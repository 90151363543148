import * as React from 'react';
import { useState, useEffect, createElement, useRef } from 'react';

/** Material UI Packages. */
import { useTheme } from '@mui/material/styles';
import {
    FormControl,
    InputLabel,
    Select,
    MenuItem,
    TextField,
    Button,
    Grid,
    Divider,
    Stack,
    Box,
    Chip,
    OutlinedInput,
    Typography,
} from '@mui/material';
import { DataGridPremium, GridRowEditStopReasons, GridRowModes } from '@mui/x-data-grid-premium';
import { GridActionsCellItem } from '@mui/x-data-grid-pro';

/** Material UI Icons. */
import PersonAddAltIcon from '@mui/icons-material/PersonAddAlt';
import EditIcon from '@mui/icons-material/Edit';
import SaveIcon from '@mui/icons-material/Save';
import CancelIcon from '@mui/icons-material/Close';

/** Internal Components. */
import CustomSnackbar from '../../CustomSnackbar';
import useApiRequest from '../../useApiRequest';
import { customized_style } from '../../../theme/Styles';
import { CustomExportToolbar } from '../../CustomExport';

/** Constants for menu styling. */
const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const menuprops = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250,
        },
    },
};

/** Function to determine styles based on selected items. */
function getStyles(name, array, theme) {
    return {
        fontWeight: array.indexOf(name) === -1 ? theme.typography.fontWeightRegular : theme.typography.fontWeightMedium,
        backgroundColor: array.indexOf(name) === -1 ? 'white' : '#ebf3f5',
    };
}

export default function TradePartnerEntry(props) {
    /** Get props from App.js.  */
    const { userId } = props;
    /** Get props from DataEntry.js.  */
    const { cityInfo, trader, carriers, setTrader } = props;

    /** API result in state */
    const [partnerType, setPartnertype] = useState('');
    const [city, setCity] = useState('');
    const [carrier, setCarrier] = useState([]);

    /** Custom API context */
    const apiRequest = useApiRequest();

    /** State for managing the snackbar that displays the result of an API request. */
    const [snackbar, setSnackbar] = useState({ open: false, message: '', severity: 'success' });

    const theme = useTheme();
    /** TradePartner DataGrid Row. */
    const [tradePartner, setTradePartner] = useState([]);
    /** When double-click, the row will be activated on Datagrid. */
    const [rowModesModel, setRowModesModel] = useState({});
    /** To keep the revised partner_carrier data. */
    const carrierRef = useRef(null);

    /** New input data is added on Datagrid. */
    const addNewPartner = (event) => {
        event.preventDefault(); // To prevent Datagrid re-rendering.
        const data = new FormData(event.target);
        const last_id = tradePartner.length === 0 ? 0 : tradePartner[tradePartner.length - 1].id;
        const partner_type_name =
            data.get('partnerType') === '0' ? 'Client' : data.get('partnerType') === '1' ? 'Terminal' : 'Yard';
        const city_name = cityInfo.filter((item) => item.id === parseInt(data.get('city')))[0].name;
        const new_data = {
            id: parseInt(last_id) + 1,
            partner_type: partner_type_name,
            partner_name: data.get('name').toUpperCase(),
            partner_city: city_name,
            partner_carrier: carrier,
            partner_link: data.get('partner_link'),
            status: true,
        };
        const new_tradePartner = [...tradePartner, new_data];
        setTradePartner(new_tradePartner);
        apiRequest('dataentry', 'trader', userId, new_data)
            .then((response) => {
                if (response.statusCode === 200) {
                    setSnackbar({ open: true, message: response.body.message, severity: 'success' });
                    setTrader(response.body.data);
                } else {
                    setSnackbar({ open: true, message: response.body.message, severity: 'error' });
                }
            })
            .catch((error) => {
                setSnackbar({ open: true, message: 'Something went wrong. Please try again.', severity: 'error' });
            });
    };

    /** Handler to chage state (partnertype/city/carrier). */
    const selectPartnerChange = (event) => {
        setPartnertype(event.target.value);
    };
    const selectCityChange = (event) => {
        setCity(event.target.value);
    };
    const selectCarrierChange = (event) => {
        const {
            target: { value },
        } = event;
        // Convert the value to an array if it is a string
        const selectedValues = typeof value === 'string' ? value.split(',') : value;
        setCarrier(selectedValues);
    };

    /** Deactivate edit row mode on Datagrid. */
    const handleRowEditStop = (params, event) => {
        if (params.reason === GridRowEditStopReasons.rowFocusOut) {
            event.defaultMuiPrevented = true;
        }
    };

    /** Datagrid Row edit handler for trade partner info. */
    const handleEditClick = (id) => () => {
        setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.Edit } });
    };

    /** Datagrid Row save handler for trade partner info. */
    const handleSaveClick = (id) => () => {
        setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.View } });
    };

    /** rowModesModel state change to View mode.   */
    const handleCancelClick = (id) => () => {
        setRowModesModel({
            ...rowModesModel,
            [id]: { mode: GridRowModes.View, ignoreModifications: true },
        });
        const editedRow = tradePartner.find((row) => row.id === id);
        if (editedRow.isNew) {
            setTradePartner(tradePartner.filter((row) => row.id !== id));
        }
    };

    /** When double click, activate Row to change. */
    const handleRowModesModelChange = (newRowModesModel) => {
        setRowModesModel(newRowModesModel);
    };

    /** Handler to update row with new values for changed parts. */
    const processRowUpdate = (newRow) => {
        const updatedRow = { ...newRow, isNew: false };
        if (carrierRef.current != null) {
            updatedRow.partner_carrier = carrierRef.current;
        }

        // setTradePartner([...tradePartner].map((row) => (row.id === newRow.id ? updatedRow : row)));
        apiRequest('dataentry', 'trader', userId, updatedRow)
            .then((response) => {
                if (response.statusCode === 200) {
                    setSnackbar({ open: true, message: response.body.message, severity: 'success' });
                    setTrader(response.body.data);
                } else {
                    setSnackbar({ open: true, message: response.body.message, severity: 'error' });
                }
                carrierRef.current = null;
            })
            .catch((error) => {
                setSnackbar({ open: true, message: 'Something went wrong. Please try again.', severity: 'error' });
            });

        return updatedRow;
    };

    /** Populate Trade Partner and Carrier state from data retrieved through API. */
    useEffect(() => {
        const new_trader = [...trader].map((item) => {
            if (item.carrier === null) {
                item.carrier = [];
            }
            return item;
        });
        setTradePartner(new_trader);
    }, [trader]);

    /**
     * @brief Handles the change event for a select input in the trade partner rows.
     *
     * @param {*} params -  Parameters containing information about the specific row.
     * @param {*} event  -  The change event object from the select input.
     */
    const handleSelectChange = (params, event) => {
        const updatedRows = [...tradePartner].map((row) => {
            if (row.id === params.id) {
                if (event.target.value ?? [0].includes(event.target.value ?? [1])) {
                    const new_value = event.target.value ?? [0].filter((item) => item !== event.target.value ?? [1]);
                    row = { ...row, partner_carrier: new_value };
                    carrierRef.current = new_value;
                } else {
                    const new_value = event.target.value.flat();
                    row = { ...row, partner_carrier: new_value };
                    carrierRef.current = new_value;
                }
            }
            return row;
        });
        setTradePartner(updatedRows);
    };

    /** Define tradePartner Datagrid Fields. */
    const tradePartner_col = [
        { field: 'id', headerName: 'ID', width: 50, align: 'center', headerAlign: 'center' },
        {
            field: 'partner_type',
            headerName: 'Partner Type',
            width: 120,
            editable: true,
            type: 'singleSelect',
            valueOptions: ['Client', 'Terminal', 'Yard'],
            align: 'center',
            headerAlign: 'center',
        },
        {
            field: 'partner_name',
            headerName: 'Name',
            width: 200,
            editable: true,
            align: 'center',
            headerAlign: 'center',
        },
        {
            field: 'partner_city',
            headerName: 'City',
            width: 200,
            editable: true,
            type: 'singleSelect',
            valueOptions: [...cityInfo].map((item) => {
                return item.name;
            }),
            align: 'center',
            headerAlign: 'center',
        },
        {
            field: 'partner_carrier',
            headerName: 'Carrier',
            width: 200,
            editable: true,
            renderCell: (params) =>
                createElement(
                    Typography,
                    {
                        disabled: true,
                        style: { width: '100%', fontSize: '14px' },
                        inputprops: {
                            style: { fontSize: '14px' },
                        },
                    },
                    [...tradePartner]
                        .filter((item) => item.id === parseInt(params.id))
                        .map((item) => item.partner_carrier)
                        ? [...tradePartner]
                              .filter((item) => item.id === parseInt(params.id))
                              .map((item) => item.partner_carrier)
                              .join(', ')
                        : ''
                ),

            renderEditCell: (params) =>
                createElement(
                    Select,
                    {
                        value: [...tradePartner]
                            .filter((item) => item.id === parseInt(params.id))
                            .map((item) => item.partner_carrier)
                            .flat(),
                        onChange: (event) => handleSelectChange(params, event),
                        style: { width: '100%' },
                        multiple: true,
                        renderValue: (selected) => (
                            <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.3 }}>
                                {selected.length > 0 ? (
                                    selected.map((value) => <Chip key={value} label={value} />)
                                ) : (
                                    <></>
                                )}
                            </Box>
                        ),
                        MenuProps: { menuprops },
                    },
                    [...carriers].map((option) =>
                        createElement(
                            MenuItem,
                            {
                                key: option.id,
                                value: option.name,
                                style: getStyles(
                                    option.name,
                                    [...tradePartner]
                                        .filter((item) => params.id === item.id)
                                        .map((item) => item.partner_carrier)
                                        .flat(),
                                    theme
                                ),
                            },
                            option.name
                        )
                    )
                ),
            valueOptions: [...carriers].map((item) => {
                return item.name;
            }),
            align: 'center',
            headerAlign: 'center',
        },
        {
            field: 'partner_link',
            headerName: 'Link',
            width: 200,
            editable: true,
            align: 'center',
            headerAlign: 'center',
        },
        {
            field: 'status',
            headerName: 'Status',
            width: 100,
            editable: true,
            type: 'boolean',
            default: 'true',
            align: 'center',
            headerAlign: 'center',
        },
        {
            field: 'actions',
            type: 'actions',
            headerName: 'Actions',
            width: 100,
            cellClassName: 'actions',
            align: 'center',
            headerAlign: 'center',
            getActions: ({ id }) => {
                const isInEditMode = rowModesModel[id]?.mode === GridRowModes.Edit;

                if (isInEditMode) {
                    return [
                        <GridActionsCellItem
                            icon={<SaveIcon />}
                            label="Save"
                            sx={{
                                color: '#00647e',
                            }}
                            onClick={handleSaveClick(id)}
                        />,
                        <GridActionsCellItem
                            icon={<CancelIcon />}
                            label="Cancel"
                            className="textPrimary"
                            onClick={handleCancelClick(id)}
                            sx={{
                                color: '#00647e',
                            }}
                        />,
                    ];
                }

                return [
                    <GridActionsCellItem
                        icon={<EditIcon />}
                        label="Edit"
                        className="textPrimary"
                        onClick={handleEditClick(id)}
                        color="inherit"
                    />,
                ];
            },
        },
    ];

    return (
        <Grid container>
            <Grid item xs={3}>
                <Box component="form" onSubmit={addNewPartner}>
                    <FormControl fullWidth>
                        <InputLabel id="partnerType" required>
                            Partner Type
                        </InputLabel>
                        <Select
                            labelId="partnerType"
                            id="partnerType"
                            name="partnerType"
                            value={partnerType}
                            label="Partner Type"
                            onChange={selectPartnerChange}
                            required
                        >
                            <MenuItem value={0}>Client</MenuItem>
                            <MenuItem value={1}>Terminal</MenuItem>
                            <MenuItem value={2}>Yard</MenuItem>
                        </Select>
                    </FormControl>
                    <TextField
                        required
                        fullWidth
                        id="name"
                        label="Name"
                        name="name"
                        autoComplete="name"
                        sx={{ mt: 2 }}
                    />
                    <FormControl fullWidth sx={{ mt: 2 }}>
                        <InputLabel id="city" required>
                            City
                        </InputLabel>
                        <Select
                            labelId="city"
                            id="city"
                            label="city"
                            name="city"
                            value={city}
                            onChange={selectCityChange}
                            required
                        >
                            {cityInfo.map((city) => (
                                <MenuItem key={city.id} value={city.id}>
                                    {city.name}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                    <FormControl fullWidth sx={{ mt: 2 }}>
                        <InputLabel id="carrier">Carrier</InputLabel>
                        <Select
                            labelId="carrier"
                            id="carrier"
                            label="carrier"
                            name="carrier"
                            value={carrier}
                            multiple
                            onChange={selectCarrierChange}
                            input={<OutlinedInput id="carrier_multiselect" label="carrier_multiselect" />}
                            renderValue={(selected) => (
                                <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                                    {selected.map((value) => (
                                        <Chip key={value} label={value} />
                                    ))}
                                </Box>
                            )}
                            MenuProps={menuprops}
                        >
                            {[...carriers].map((item) => (
                                <MenuItem key={item.id} value={item.name} style={getStyles(item.name, carrier, theme)}>
                                    {item.name}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                    <TextField
                        fullWidth
                        id="partner_link"
                        label="Website Link"
                        name="partner_link"
                        autoComplete="partner_link"
                        sx={{ mt: 2 }}
                    />
                    <Stack direction="row" justifyContent="center">
                        <Button
                            type="submit"
                            variant="contained"
                            startIcon={<PersonAddAltIcon />}
                            sx={{ ...customized_style.itemButtons_add, width: '57%', mt: 3 }}
                        >
                            Add New Partner
                        </Button>
                        <CustomSnackbar snackbar={snackbar} setSnackbar={setSnackbar} />
                    </Stack>
                </Box>
            </Grid>
            <Divider orientation="vertical" flexItem sx={{ mx: 3 }} />
            <Grid item xs={8.5} sx={{ height: '77vh' }}>
                <DataGridPremium
                    rows={tradePartner}
                    columns={tradePartner_col}
                    editMode="row"
                    density="compact"
                    unstable_cellSelection
                    rowModesModel={rowModesModel}
                    onRowModesModelChange={handleRowModesModelChange}
                    onRowEditStop={handleRowEditStop}
                    processRowUpdate={processRowUpdate}
                    disableRowSelectionOnClick
                    sx={customized_style.itemDatagrid}
                    slots={{ toolbar: CustomExportToolbar }}
                    initialState={{
                        pagination: { paginationModel: { pageSize: 25 } },
                    }}
                    pagination={true}
                    pageSizeOptions={[25, 50, 100]}
                />
            </Grid>
        </Grid>
    );
}
