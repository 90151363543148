import * as React from 'react';
import { useState, useEffect } from 'react';

/** Material UI Packages. */
import { Dialog, Box, AppBar, Toolbar, IconButton, Typography, Divider } from '@mui/material';
import { DataGridPremium, gridClasses } from '@mui/x-data-grid-premium';

/** Material UI Icons. */
import CloseIcon from '@mui/icons-material/Close';

/** JavaScript Utility Library. */
import _ from 'lodash';

/** Internal Components. */
import useApiRequest from '../../../useApiRequest';
import { CustomSkeletonVer2 } from '../../../CustomSkeleton';

export default function ChassisDialog(props) {
    /** Get props from App.js. */
    const { userId } = props;
    /** Get props from ChassisStatus.js. */
    const { chassisOpen, setChassisOpen, setSnackbar } = props;

    /** Custom API Request function. */
    const apiRequest = useApiRequest();

    /** State for datagrid row.  */
    const [chassisRow, setChassisRow] = useState([]);
    /** State for Chassis QTY managing. */
    const [chassisQTY, setChassisQTY] = useState(0);
    const [availQTY, setAvailQTY] = useState(0);
    /** Managing for loading useing skeleton. */
    const [loaded, setLoaded] = useState(false);

    /** Fetch chassis data on mount */
    useEffect(() => {
        const fetchChassisData = async () => {
            try {
                const response = await apiRequest('schedule', 'get_chassis', userId, []);
                if (response.statusCode === 200) {
                    setChassisRow(response.body.data);
                    setLoaded(true);
                }
            } catch (error) {
                console.error('Failed to fetch chassis data:', error);
            }
        };
        fetchChassisData();
    }, [userId]);

    /** Update the quantity of chassis and available chassis */
    useEffect(() => {
        setChassisQTY(chassisRow.length);
        setAvailQTY(chassisRow.filter((row) => row.location && row.location !== '---').length);
    }, [chassisRow]);

    /** Handle dialog close */
    const handleClickClose = () => setChassisOpen(false);

    /** Handler to update row with new values for changed parts. */
    const processRowUpdate = (newRow) => {
        apiRequest('schedule', 'chassis', userId, newRow)
            .then((response) => {
                if (response.statusCode === 200) {
                    setSnackbar({ open: true, message: response.body.message, severity: 'success' });
                    setChassisRow((prev) => prev.map((row) => (row.id === newRow.id ? { ...row, ...newRow } : row)));
                } else {
                    setSnackbar({ open: true, message: response.body.message, severity: 'error' });
                }
            })
            .catch((error) => {
                setSnackbar({ open: true, message: error.body.message, severity: 'error' });
            });
        return newRow;
    };

    /** Define ChassisStatus Datagrid Fields. */
    const chassis_col = [
        { field: 'id', headerName: 'ID', width: 50, align: 'center', headerAlign: 'center', hide: true },
        {
            field: 'name',
            headerName: 'Chassis#',
            width: 130,
            align: 'center',
            headerAlign: 'center',
        },
        {
            field: 'location',
            headerName: 'Storage Location',
            width: 200,
            editable: true,
            type: 'singleSelect',
            valueOptions: ['---', '1st Yard - SouthWest', '2nd Yard - Insight'],
            align: 'center',
            headerAlign: 'center',
            valueGetter: (params) => params.row.location || '---',
            valueFormatter: (params) => (params.value === '---' ? null : params.value),
        },
        {
            field: 'container',
            headerName: 'Last Assigned CNTR#',
            width: 160,
            align: 'center',
            headerAlign: 'center',
        },
        {
            field: 'remark',
            headerName: 'Remark',
            width: 630,
            editable: true,
            align: 'center',
            headerAlign: 'center',
        },
    ];

    return (
        <>
            <Dialog maxWidth="lg" fullWidth={true} open={chassisOpen} onClose={handleClickClose}>
                <Box sx={{ height: 620 }}>
                    <AppBar sx={{ bgcolor: '#00647e', position: 'relative' }}>
                        <Toolbar>
                            <IconButton edge="start" color="inherit" onClick={handleClickClose} aria-label="close">
                                <CloseIcon />
                            </IconButton>
                            <Typography sx={{ ml: 2, flex: 1, fontWeight: 'bold' }} variant="h6">
                                Chassis Status
                            </Typography>
                        </Toolbar>
                    </AppBar>
                    {loaded ? (
                        <>
                            <Box
                                sx={{
                                    display: 'flex',
                                    m: 1,
                                    color: 'text.secondary',
                                }}
                            >
                                <Typography variant="h6" component="h6" sx={{ mx: 1 }}>
                                    All : {chassisQTY}
                                </Typography>
                                <Divider orientation="vertical" flexItem />
                                <Typography variant="h6" component="h6" sx={{ color: '#a7c957', mx: 1 }}>
                                    Avaliable : {availQTY}
                                </Typography>
                            </Box>
                            <DataGridPremium
                                rows={chassisRow || []}
                                columns={chassis_col}
                                density="compact"
                                editMode="row"
                                processRowUpdate={processRowUpdate}
                                unstable_cellSelection
                                disableRowSelectionOnClick
                                sx={{
                                    height: 500,
                                    [`& .${gridClasses.cell}`]: {
                                        py: 1,
                                    },
                                }}
                            />
                        </>
                    ) : (
                        <CustomSkeletonVer2 />
                    )}
                </Box>
            </Dialog>
        </>
    );
}
