import * as React from 'react';
import { useState, useEffect } from 'react';

/** Material UI Packages. */
import { Tabs, Tab, Box, Typography, Paper } from '@mui/material';
import PropTypes from 'prop-types';

/** Auth0 authenticator Packages. */
import { useAuth0 } from '@auth0/auth0-react';

/** HTTP Client Liabrary. */
import axios from 'axios';

/** Internal Components. */
import { YearlyPayroll, MonthlyPayroll, WeeklyPayroll } from './PayrollData';
import { customized_style } from '../../../theme/Styles';
import { CustomSkeletonVer2 } from '../../CustomSkeleton';

import { addDays } from 'date-fns';

/** Create tabPanel component. */
function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`full-width-tabpanel-${index}`}
            aria-labelledby={`full-width-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ p: 3 }}>
                    <Typography component="div">{children}</Typography>
                </Box>
            )}
        </div>
    );
}

/** To run typechecking on the props for a component. */
TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
};

export default function Payroll(props) {
    /** Get props from App.js.  */
    const { userId } = props;
    /**  Get access token value. */
    const { getAccessTokenSilently } = useAuth0();

    /** Tab index value. */
    const [tabValue, setTapValue] = useState(0);

    /** State for payroll data based on period. */
    const [yearlyPayroll, setYearlyPayroll] = useState([]);
    const [monthlyPayroll, setMonthlyPayroll] = useState([]);
    const [weeklyPayroll, setWeeklyPayroll] = useState([]);

    /** State for API loading. */
    const [loaded, setLoaded] = useState(false);

    /** Set document title. */
    useEffect(() => {
        document.title = 'Payroll | Dispatch System';
    }, []);

    /** Initialized selected date. */
    const [dateState, setDateState] = useState([
        {
            startDate: addDays(new Date(), -6),
            endDate: new Date(),
            key: 'selection',
        },
    ]);

    /** Handler to change tabValue . */
    const tabHandleChange = (event, newValue) => {
        setTapValue(newValue);
    };

    /** Async API call to obtain payroll data. */
    useEffect(() => {
        (async () => {
            try {
                const token = await getAccessTokenSilently({
                    authorizationParams: {
                        audience:
                            'https://insightshippinginc.us.auth0.com/api/v2/' /** Include the audience of authorization parameters to authenticate API call. */,
                    },
                });
                const response = await axios.get('https://icxhzxkwxa.execute-api.us-west-1.amazonaws.com/api', {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                    params: {
                        type: 'payroll',
                        userId: userId,
                    },
                });
                const resp = response.data['body-json'];
                setYearlyPayroll(resp.body.yearly_payroll);
                setMonthlyPayroll(resp.body.monthly_payroll);
                setWeeklyPayroll(resp.body.weekly_payroll);
                setLoaded(true);
            } catch (e) {
                throw e;
            }
        })();
    }, [getAccessTokenSilently]);

    return (
        <>
            {loaded ? (
                <Paper sx={customized_style.itemPaper_main}>
                    <Box>
                        <Tabs
                            value={tabValue}
                            onChange={tabHandleChange}
                            indicatorColor="primary"
                            textColor="inherit"
                            variant="fullWidth"
                        >
                            <Tab label="Yearly" />
                            <Tab label="Monthly" />
                            <Tab label="Weekly" />
                        </Tabs>
                        <TabPanel value={tabValue} index={0}>
                            <YearlyPayroll yearlyPayroll={yearlyPayroll} />
                        </TabPanel>
                        <TabPanel value={tabValue} index={1}>
                            <MonthlyPayroll monthlyPayroll={monthlyPayroll} />
                        </TabPanel>
                        <TabPanel value={tabValue} index={2}>
                            <WeeklyPayroll
                                weeklyPayroll={weeklyPayroll}
                                setWeeklyPayroll={setWeeklyPayroll}
                                userId={userId}
                                dateState={dateState}
                                setDateState={setDateState}
                            />
                        </TabPanel>
                    </Box>
                </Paper>
            ) : (
                <Paper sx={{ ...customized_style.itemPaper_main, p: 2 }}>
                    <CustomSkeletonVer2 />
                </Paper>
            )}
        </>
    );
}
