import * as React from 'react';
import { useState } from 'react';

/** Material UI Packages. */
import { Fab } from '@mui/material';

/** Material UI Icons. */
import EditNoteIcon from '@mui/icons-material/EditNote';

/** Internal Components. */
import MemoDialog from './MemoDialog';
import CustomSnackbar from '../../../CustomSnackbar';
import useApiRequest from '../../../useApiRequest';

export default function MemoFab(props) {
    /** Get props from App.js.  */
    const { userId } = props;

    /** Custom API Request function */
    const apiRequest = useApiRequest();

    /** State for managing the snackbar that displays the result of an API request. */
    const [snackbar, setSnackbar] = useState({ open: false, message: '', severity: 'success' });

    /** State for datagrid row. */
    const [memoRow, setMemoRow] = useState([]);
    /** Dialog open state using Fab component. */
    const [memoOpen, setMemoOpen] = useState(false);

    /** Handler to open dialog & Receive memo data through API. */
    const handleFabClick = () => {
        setMemoOpen(true);
        apiRequest('schedule', 'memo-read', userId, [])
            .then((response) => {
                if (response.statusCode === 200) {
                    setMemoRow(response.body.data);
                } else {
                    setSnackbar({ open: true, message: response.body.message, severity: 'error' });
                }
            })
            .catch((error) => {
                setSnackbar({ open: true, message: 'Something went wrong. Please try again.', severity: 'error' });
            });
    };

    return (
        <>
            <Fab
                size="medium"
                color="primary"
                aria-label="add"
                sx={{ position: 'absolute', bottom: 135, right: 16 }}
                onClick={handleFabClick}
            >
                <EditNoteIcon />
            </Fab>
            <MemoDialog
                userId={userId}
                memoOpen={memoOpen}
                memoRow={memoRow}
                setMemoRow={setMemoRow}
                setMemoOpen={setMemoOpen}
                setSnackbar={setSnackbar}
            />
            <CustomSnackbar snackbar={snackbar} setSnackbar={setSnackbar} />
        </>
    );
}
