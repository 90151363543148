import * as React from 'react';
import { useEffect } from 'react';

/** Material UI Packages. */
import { Box, Typography, Link } from '@mui/material';

export default function Copyright(props) {
    return (
        <Box sx={{ position: 'absolute', zIndex: 3, top: '97%', right: '2%' }}>
            <Typography variant="body2" color="text.secondary" align="center" {...props}>
                {'Copyright © '}
                <Link color="inherit" href="https://www.insightshippinginc.com/">
                    Insight Shipping, Inc.
                </Link>{' '}
                {new Date().getFullYear()}
                {'.'}
            </Typography>
        </Box>
    );
}
