import * as React from 'react';
import { useState, forwardRef, useEffect } from 'react';

/** Material UI Packages. */
import { Box, Button, Slide, Dialog, AppBar, Toolbar, IconButton, Typography, Grid } from '@mui/material';

/** Material UI Icons. */
import SaveIcon from '@mui/icons-material/Save';
import CloseIcon from '@mui/icons-material/Close';
import InfoIcon from '@mui/icons-material/Info';

/** JavaScript Utility Library. */
import _ from 'lodash';

/** Internal Components. */
import { customized_style } from '../../../../theme/Styles';
import useApiRequest from '../../../useApiRequest';
import NewMoveInput from './NewMoveInput';
import NewMoveTable from './NewMoveTable';
import NewMoveInfo from './NewMoveInfo';

/*  Showing dialog effect  */
const Transition = forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

export default function NewMoveDialog(props) {
    /** Get props from Schedule.js. */
    const { assigned, dispatch, setDispatch, selectedId, locationOptions, userId, chassis, newMove, setNewMove } =
        props;
    /** Get props from NewContainer.js.  */
    const { newMovementDialogOpen, setNewMovementDialogOpen, tempMoveRow, setTempMoveRow, containerNum } = props;
    /** Get props from NewContainer.js for Snackbar. */
    const { setSnackbar } = props;

    /** Custom API Request function */
    const apiRequest = useApiRequest();

    /** Check a state for save button activation. */
    const [saveBtnDisable, setSaveBtnDisable] = useState(true);
    const [filteredDispatch, setFilteredDispatch] = useState([]);

    /** Date format for datagrid. */
    const datetime_format = { month: '2-digit', day: '2-digit', hour: '2-digit', minute: '2-digit', hour12: false };

    useEffect(() => {
        setFilteredDispatch(
            [...dispatch].filter((item) => {
                return item.id == selectedId;
            })
        );
    }, [selectedId]);

    /** Handler to update temp data to actual data & open Snackbar. */
    const handleSaveBtn = () => {
        const api_dispatch_movement = _.cloneDeep(dispatch)
            .filter((item) => item.id === selectedId)
            .map((item) => {
                item.movement = tempMoveRow;
                return item;
            });

        apiRequest('schedule', 'moverow', userId, api_dispatch_movement)
            .then((response) => {
                if (response.statusCode === 200) {
                    setSnackbar({ open: true, message: response.body.message, severity: 'success' });
                    setNewMove(response.body.data);
                } else {
                    setSnackbar({ open: true, message: response.body.message, severity: 'error' });
                }
            })
            .catch((error) => {
                setSnackbar({ open: true, message: error.body.message, severity: 'error' });
            });
        setSaveBtnDisable(true);
    };

    useEffect(() => {
        const updatedMoveRows = [...dispatch].map((item) => {
            const matching = newMove.find((b) => b.id === item.id);
            return matching ? matching : item;
        });
        setDispatch(updatedMoveRows);
    }, [newMove]);

    /** Handler to update temp data to actual data & open Snackbar & close dialog */
    const handleSaveCloseBtn = () => {
        handleSaveBtn();
        setNewMovementDialogOpen(false);
    };

    /** Handler to remind save and close a dialog for movement. */
    const handleMovementDialogClose = () => {
        if (!saveBtnDisable) {
            if (window.confirm('Are you sure you want to exit this window without save? ')) {
                setSaveBtnDisable(true);
                return setNewMovementDialogOpen(false);
            }
            return;
        }
        setNewMovementDialogOpen(false);
    };

    return (
        <Dialog
            maxWidth="xl"
            open={newMovementDialogOpen}
            onClose={handleMovementDialogClose}
            TransitionComponent={Transition}
        >
            <Box sx={{ height: 540 }}>
                <AppBar sx={{ bgcolor: '#00647e', position: 'relative' }}>
                    <Toolbar>
                        <IconButton edge="start" color="inherit" onClick={handleMovementDialogClose} aria-label="close">
                            <CloseIcon />
                        </IconButton>
                        <Typography sx={{ ml: 2, flex: 1, fontWeight: 'bold' }} variant="h6">
                            Movement
                        </Typography>
                        <Button
                            color="inherit"
                            disabled={saveBtnDisable}
                            sx={customized_style.itemButtons_save}
                            onClick={handleSaveBtn}
                        >
                            <SaveIcon />
                            SAVE
                        </Button>
                        <Button
                            color="inherit"
                            disabled={saveBtnDisable}
                            sx={customized_style.itemButtons_save_close}
                            onClick={handleSaveCloseBtn}
                        >
                            <SaveIcon />
                            SAVE & CLOSE
                        </Button>
                    </Toolbar>
                </AppBar>
                <Box sx={{ my: 2, mx: 5 }}>
                    <Box sx={{ display: 'inline-flex', alignItems: 'center' }}>
                        <InfoIcon sx={{ display: 'inline', color: '#adb5bd', mr: 1 }} />
                        <Typography sx={customized_style.itemMoveTypography}>Client : </Typography>
                        <Typography sx={customized_style.itemValueTypography}>{filteredDispatch[0]?.client}</Typography>
                        <Typography sx={customized_style.itemMoveTypography}>/ MBL# : </Typography>
                        <Typography sx={customized_style.itemValueTypography}>{filteredDispatch[0]?.mbl}</Typography>
                        <Typography sx={customized_style.itemMoveTypography}>/ CNTR# : </Typography>
                        <Typography sx={customized_style.itemValueTypography}>{filteredDispatch[0]?.cntr}</Typography>
                        <Typography sx={customized_style.itemMoveTypography}> / Size : </Typography>
                        <Typography sx={customized_style.itemValueTypography}> {filteredDispatch[0]?.size} </Typography>
                        <Typography sx={customized_style.itemMoveTypography}> / Terminal : </Typography>
                        <Typography sx={customized_style.itemValueTypography}>
                            {filteredDispatch[0]?.terminal}
                        </Typography>
                        <Typography sx={customized_style.itemMoveTypography}> / APPT : </Typography>
                        <Typography sx={customized_style.itemValueTypography}>
                            {new Date(filteredDispatch[0]?.appt).toLocaleDateString('en-US', datetime_format)}
                        </Typography>
                    </Box>
                    <Grid container spacing={2}>
                        <Grid item xs={7.5} sx={{ height: 410 }}>
                            <NewMoveTable
                                saveBtnDisable={saveBtnDisable}
                                setSaveBtnDisable={setSaveBtnDisable}
                                tempMoveRow={tempMoveRow}
                                setTempMoveRow={setTempMoveRow}
                                assigned={assigned}
                                locationOptions={locationOptions}
                                dispatch={dispatch}
                                containerNum={containerNum}
                                chassis={chassis}
                            />
                        </Grid>
                        <Grid item xs={2.5}>
                            <NewMoveInput
                                saveBtnDisable={saveBtnDisable}
                                setSaveBtnDisable={setSaveBtnDisable}
                                tempMoveRow={tempMoveRow}
                                setTempMoveRow={setTempMoveRow}
                                locationOptions={locationOptions}
                                containerNum={containerNum}
                                chassis={chassis}
                            />
                        </Grid>
                        <Grid item xs={2}>
                            <NewMoveInfo selectedId={selectedId} dispatch={dispatch} />
                        </Grid>
                    </Grid>
                </Box>
            </Box>
        </Dialog>
    );
}
