import * as React from 'react';
import { useEffect } from 'react';

/** Material UI Packages. */
import { Box, Typography, createTheme, ThemeProvider, Button } from '@mui/material';
import { DataGridPremium } from '@mui/x-data-grid-premium';

/** Material UI Icons. */
import AutorenewIcon from '@mui/icons-material/Autorenew';
import SaveIcon from '@mui/icons-material/Save';

/** Internal Components. */
import { CustomExportToolbar } from '../../../CustomExport';
import CustomSnackbar from '../../../CustomSnackbar';
import { CustomSkeletonVer2 } from '../../../CustomSkeleton';
import useScheduleTable from './useScheduleTable';

/** Set up general theme. */
const theme = createTheme({
    palette: {
        primary: { light: '#80b2bf', main: '#00647e', dark: '#003a52' },
    },
    typography: {
        fontFamily: 'NotoSans',
        fontWeightRegular: 400,
        fontWeightBold: 600,
        fontSize: 14,
    },
});

/** Main component for the schedule table. */
const ScheduleTable = ({ userId, type, columns }) => {
    /** Use the custom hook to get necessary data and functions. */
    const {
        rows,
        loaded,
        disableBtn,
        handleRowOrderChange,
        handleSave,
        handleRefresh,
        buttonRef,
        snackbar,
        setSnackbar,
    } = useScheduleTable(userId, type);

    /** Function to handle key press events for save button. */
    useEffect(() => {
        const handleKeyPress = (event) => {
            if ((event.ctrlKey || event.metaKey) && event.key === 's') {
                event.preventDefault();
                buttonRef.current.click();
            }
        };
        window.addEventListener('keydown', handleKeyPress);
        return () => {
            window.removeEventListener('keydown', handleKeyPress);
        };
    }, [buttonRef]);

    return (
        <ThemeProvider theme={theme}>
            <Box sx={{ p: 2 }}>
                {loaded ? (
                    <>
                        <Box sx={{ mb: 2 }}>
                            <Typography
                                sx={{
                                    fontSize: '1.5rem',
                                    fontWeight: 'bold',
                                    color: '#003a52',
                                    display: 'inline',
                                    mr: 2,
                                }}
                            >
                                {type === 'loadOrder' ? 'Load D/O List' : 'Empty D/O List'}
                            </Typography>
                            <Button
                                sx={{
                                    color: '#00647e',
                                    fontWeight: 'bold',
                                    borderRadius: 5,
                                    border: '1px solid #00647e',
                                    py: 0.3,
                                    px: 1,
                                    ml: 1,
                                }}
                                startIcon={<AutorenewIcon fontSize="small" />}
                                onClick={handleRefresh}
                            >
                                Refresh
                            </Button>
                            <Button
                                ref={buttonRef}
                                sx={{
                                    color: 'white',
                                    backgroundColor: disableBtn ? 'grey' : '#003a52',
                                    fontWeight: 'bold',
                                    borderRadius: 5,
                                    border: disableBtn ? '1px solid grey' : '1px solid #003a52',
                                    py: 0.3,
                                    px: 1,
                                    ml: 1,
                                }}
                                startIcon={<SaveIcon fontSize="small" />}
                                onClick={handleSave}
                                disabled={disableBtn}
                            >
                                Save
                            </Button>
                        </Box>
                        <Box sx={{ width: '100%', height: '90vh' }}>
                            <DataGridPremium
                                rows={rows}
                                columns={columns}
                                density="compact"
                                disableRowSelectionOnClick
                                disalbec
                                slots={{ toolbar: CustomExportToolbar }}
                                slotProps={{
                                    toolbar: {
                                        showQuickFilter: true,
                                    },
                                }}
                                rowReordering
                                onRowOrderChange={handleRowOrderChange}
                            />
                        </Box>
                    </>
                ) : (
                    <CustomSkeletonVer2 />
                )}
                <CustomSnackbar snackbar={snackbar} setSnackbar={setSnackbar} />
            </Box>
        </ThemeProvider>
    );
};

export default ScheduleTable;
