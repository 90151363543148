import * as React from 'react';
import { useState } from 'react';

/** Material UI Packages. */
import { Box, Typography, FormControl, MenuItem, Select, TextField, Button } from '@mui/material';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { MultiInputDateRangeField } from '@mui/x-date-pickers-pro/MultiInputDateRangeField';

/** Internal Components. */
import CustomSnackbar from '../../CustomSnackbar';
import useApiRequest from '../../useApiRequest';

export default function HistorySearch(props) {
    /** Get props from App.js.  */
    const { userId } = props;
    /** Get props from History.js.  */
    const { setAllHistoryRow, setSubLoaded, snackbar, setSnackbar } = props;

    /** Custom API Request function */
    const apiRequest = useApiRequest();

    /** State for a selected date range. */
    const [selectedDate, setSelectedDate] = useState([null, null]);
    /** State for a selected type. */
    const [searchType, setSearchType] = useState('0');
    /** State for a input value. */
    const [inputValue, setInputValue] = useState('');

    /** Handle to change a search type through select. */
    const handleSearchTypeChange = (event) => {
        setSearchType(event.target.value);
    };

    /** Handle to get a TextField value. */
    const handleInputChange = (event) => {
        setInputValue(event.target.value);
    };

    /** Handle to submit search conditions and receive new data for datagrid row.  */
    const handleSearch = () => {
        const searchValue = {
            date_from: selectedDate[0],
            date_to: selectedDate[1],
            search_type: searchType,
            search_input: inputValue,
        };
        setSubLoaded(false);
        apiRequest('history', 'search', userId, searchValue)
            .then((response) => {
                const { statusCode, body } = response;
                setSnackbar({ open: true, message: body.message, severity: statusCode === 200 ? 'success' : 'error' });
                setAllHistoryRow(statusCode === 200 ? body.data : []);
            })
            .catch(() => {
                setSnackbar({ open: true, message: 'Something went wrong. Please try again.', severity: 'error' });
            })
            .finally(() => setSubLoaded(true));
    };

    /** Handle to reset all search conditions. */
    const handleReset = () => {
        setSelectedDate([null, null]);
        setSearchType('0');
        setInputValue('');
    };

    return (
        <>
            <Box sx={{ display: 'flex', alignItems: 'center', bgcolor: '#e6f2f5', borderRadius: 4, p: 2 }}>
                <Typography sx={{ display: 'inline', fontSize: '0.9rem', mr: 0.5, fontWeight: 500, color: '#003a52' }}>
                    Date Range :
                </Typography>
                <FormControl sx={{ ml: 1, width: '300px', alignItems: 'center' }}>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <MultiInputDateRangeField
                            value={selectedDate}
                            onChange={(newValue) => setSelectedDate(newValue)}
                            slotProps={{
                                textField: ({ position }) => ({
                                    label: position === 'start' ? 'Date From' : 'Date To',
                                    size: 'small',
                                    style: { fontSize: '0.9rem' },
                                    InputProps: {
                                        style: { fontSize: '0.9rem' },
                                    },
                                    InputLabelProps: {
                                        style: { fontSize: '0.9rem' },
                                    },
                                }),
                            }}
                            sx={{
                                '& .MuiInputBase-input': { p: '7px' },
                            }}
                        />
                    </LocalizationProvider>
                </FormControl>
                <FormControl variant="standard" sx={{ width: '130px', ml: 3, mr: 1 }}>
                    <Select
                        id="column_name"
                        defaultValue="client"
                        value={searchType}
                        onChange={handleSearchTypeChange}
                        label="Column"
                    >
                        {['Search Type', 'Team', 'Client', 'Booking#/MBL#', 'CNTR#', 'Terminal'].map((label, index) => (
                            <MenuItem key={index} value={index.toString()} sx={{ fontSize: '0.8rem' }}>
                                {label}
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>
                <TextField
                    id="search_input"
                    size="small"
                    value={inputValue}
                    onChange={handleInputChange}
                    sx={{ width: '200px' }}
                    InputProps={{
                        sx: { height: 35, fontSize: '0.8rem' },
                    }}
                />
                <Button
                    onClick={handleSearch}
                    sx={{
                        bgcolor: '#00657e',
                        color: 'white',
                        borderRadius: 5,
                        ml: 2,
                        px: 2,
                        ':hover': {
                            backgroundColor: '#003a52',
                            color: 'white',
                        },
                    }}
                >
                    Search
                </Button>
                <Button
                    onClick={handleReset}
                    sx={{
                        color: '#00657e',
                        borderRadius: 5,
                        border: '1px solid #00657e',
                        ml: 1,
                        px: 2,
                        ':hover': {
                            backgroundColor: '#003a52',
                            color: 'white',
                        },
                    }}
                >
                    Clear
                </Button>
                <CustomSnackbar snackbar={snackbar} setSnackbar={setSnackbar} />
            </Box>
        </>
    );
}
