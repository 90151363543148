import * as React from 'react';
import { useState, useEffect, useRef } from 'react';

/** Material UI Packages. */
import { Grid, Paper, Box } from '@mui/material';

/** Auth0 authenticator Packages. */
import { useAuth0 } from '@auth0/auth0-react';

/** HTTP Client Library. */
import axios from 'axios';

/** Date Format Library. */
import dayjs from 'dayjs';

/** Internal Components. */
import { CustomSkeleton } from '../../CustomSkeleton';
import { customized_style } from '../../../theme/Styles';
import NewContainer from './Dispatch/NewContainer';
import Timeline from './Timeline/Timeline';

export default function Schedule(props) {
    /** Get props from App.js.  */
    const { userId } = props;

    /**  Redirect user if the token is invalid */
    const { getAccessTokenSilently } = useAuth0();

    /** Initializing states for each API. */
    const [dispatch, setDispatch] = useState([]);
    const [client, setClient] = useState([]);
    const [terminal, setTerminal] = useState([]);
    const [yard, setYard] = useState([]);
    const [locationOptions, setLocationOptions] = useState([]);
    const [driver, setDriver] = useState([]);
    const [chassis, setChassis] = useState([]);

    /** State to display loading component until API response is received. */
    const [loaded, setLoaded] = useState(false);
    /** Get timeline date value. */
    const [selectedDate, setSelectedDate] = useState(dayjs());
    /* Keep deleted movement data using useRef.  */
    const assigned = useRef(null);
    /** Movement Dialog states. */
    const [newMovementDialogOpen, setNewMovementDialogOpen] = useState(false);
    /** Temporary moveRow state inside this component. */
    const [tempMoveRow, setTempMoveRow] = useState([]);
    /** Movement Dialog Row Id. */
    const [selectedId, setSelectedId] = useState(null);
    /** container number is added on tempMoveRow for timeline ref. */
    const [seletedCntr, setSelectedCntr] = useState('');

    const [newMove, setNewMove] = useState([]);

    /** Set document title. */
    useEffect(() => {
        document.title = 'Schedule | Dispatch Operation';
    }, []);

    /** Async API call to obtain dispatch - movement data. */
    useEffect(() => {
        (async () => {
            try {
                const token = await getAccessTokenSilently({
                    authorizationParams: {
                        audience:
                            'https://insightshippinginc.us.auth0.com/api/v2/' /** Include the audience of authorization parameters to authenticate API call. */,
                    },
                });
                const response = await axios.get('https://icxhzxkwxa.execute-api.us-west-1.amazonaws.com/api', {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                    params: {
                        type: 'schedule',
                        userId: userId,
                    },
                });
                const resp = response.data['body-json'];
                setDispatch(resp.body.dispatch);
                setClient(resp.body.client);
                setTerminal(resp.body.terminal);
                setYard(resp.body.yard);
                setDriver(resp.body.driver);
                setChassis(resp.body.chassis);
                const combined = [...resp.body.client, ...resp.body.terminal, ...resp.body.yard];
                setLocationOptions(combined);
                setLoaded(true);
            } catch (e) {
                throw e;
            }
        })();
    }, [getAccessTokenSilently]);

    return (
        <Box maxWidth="xxl" sx={{ mt: 2, mx: 2 }}>
            <Grid container spacing={1}>
                <Grid item lg={12}>
                    {loaded ? (
                        <Paper sx={customized_style.itemPaper}>
                            <NewContainer
                                assigned={assigned}
                                dispatch={dispatch}
                                setDispatch={setDispatch}
                                userId={userId}
                                locationOptions={locationOptions}
                                chassis={chassis}
                                selectedId={selectedId}
                                setSelectedId={setSelectedId}
                                seletedCntr={seletedCntr}
                                setSelectedCntr={setSelectedCntr}
                                tempMoveRow={tempMoveRow}
                                setTempMoveRow={setTempMoveRow}
                                newMovementDialogOpen={newMovementDialogOpen}
                                setNewMovementDialogOpen={setNewMovementDialogOpen}
                                newMove={newMove}
                                setNewMove={setNewMove}
                            />
                        </Paper>
                    ) : (
                        <Paper sx={{ height: '400px', borderRadius: 5, p: 2 }}>
                            <CustomSkeleton />
                        </Paper>
                    )}
                </Grid>
                <Grid item lg={12}>
                    <Paper overflow="auto" sx={customized_style.itemPaper}>
                        {loaded ? (
                            <Timeline
                                assigned={assigned}
                                dispatch={dispatch}
                                setDispatch={setDispatch}
                                locationOptions={locationOptions}
                                driver={driver}
                                selectedDate={selectedDate}
                                setSelectedDate={setSelectedDate}
                                userId={userId}
                                setSelectedId={setSelectedId}
                                setSelectedCntr={setSelectedCntr}
                                setTempMoveRow={setTempMoveRow}
                                setNewMovementDialogOpen={setNewMovementDialogOpen}
                                newMove={newMove}
                                setNewMove={setNewMove}
                            />
                        ) : (
                            <CustomSkeleton />
                        )}
                    </Paper>
                </Grid>
            </Grid>
        </Box>
    );
}
