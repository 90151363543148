import * as React from 'react';
import { useState, useEffect } from 'react';

/** Material UI Packages. */
import { Typography, Stack, Paper, Grid, Button } from '@mui/material';
import { DataGridPremium, GridToolbarContainer } from '@mui/x-data-grid-premium';
import { randomId } from '@mui/x-data-grid-generator';

/** Material UI Icons. */
import AddIcon from '@mui/icons-material/Add';

/** Date Format Library. */
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';

/** Internal Components. */
import useApiRequest from '../../useApiRequest';
import { customized_style } from '../../../theme/Styles';
import { additionalChargeCols } from './TableDefinition';

/** Define Activation. */
dayjs.extend(utc);

/**
 * Add a new row for additional charge detail datagrid.
 * @param {*} props
 * @returns newRows
 */
function EditToolbar(props) {
    const { setAdditionalChargeRow, editable } = props;
    const handleClick = () => {
        const id = randomId();
        setAdditionalChargeRow((oldRows) => [
            ...oldRows,
            {
                id,
                charge_type: null,
                addcharge_rate: null,
                qty: 1,
                total_rate: null,
                addcharge_note: null,
                profile_id: null,
                isNew: true,
            },
        ]);
    };

    return (
        <GridToolbarContainer>
            <Button color="primary" startIcon={<AddIcon />} onClick={handleClick} disabled={!editable}>
                ADD CHARGE
            </Button>
        </GridToolbarContainer>
    );
}

export function ExtraChargeList(props) {
    /** Get props from App.js.  */
    const { userId } = props;
    /** Get props from Manifest.js.  */
    const { active, setInprogressRow } = props;
    /** Get props from ManifestAssigned.js.  */
    const { addCharges, setSnackbar, rowProp } = props;

    /** Custom API Request function */
    const apiRequest = useApiRequest();

    /** State for additional charge detail datagrid Row. */
    const [additionalChargeRow, setAdditionalChargeRow] = useState([]);
    /** State for managing deleted row's movement_id. */
    const [lastDel, setLastDel] = useState([]);
    /** State for the rate data applied based on the move date.  */
    const [chargeList, setChargeList] = useState([]);
    const [editable, setEditable] = useState(false);

    /** Selected movement ID */
    const [selectedId, setSelectedId] = useState(null);
    /** Rate Profile ID */
    const [profileId, setProfileId] = useState(null);

    /** Initiallize additional charge datagrid row. */
    useEffect(() => {
        const addCharge = rowProp.addCharge;
        setAdditionalChargeRow(addCharge);
        setLastDel([rowProp.id]);
    }, []);

    useEffect(() => {
        if (active) setEditable(true);
        if (!active) setEditable(false);
    }, [active]);

    /** Method to sort additional charge rate data by date in ascending order, and by profile_id in ascending order if the dates are the same. */
    const sortData = (data) => {
        return data.sort((a, b) => {
            // Compare dates (oldest first).
            const dateA = a.date_applied;
            const dateB = b.date_applied;

            if (dateA > dateB) return -1;
            if (dateA < dateB) return 1;

            // If the dates are the same, sort in descending order based on profile_id.
            return b.profile_id - a.profile_id;
        });
    };

    /** Method to find the additional charge rate sheet for the given movement. */
    const filterValidData = (sortedData, rowProp) => {
        return sortedData.find((item) => {
            const moveDate = rowProp.move_date;
            const moveDateStr = moveDate.format('YYYY-MM-DDTHH:mm:ss.SSS[Z]');
            const appliedDate = item.date_applied;

            // Check if move_date is equal to or later than date_applied.
            const isFutureOrSameDate = moveDateStr >= appliedDate;

            // Check if rowProp.driver_id is included in the drivers array.
            const hasDriver = item.drivers.includes(rowProp.driver_id);

            return isFutureOrSameDate && hasDriver;
        });
    };

    /** Hook to find the chargeList and profileId matching the given movement. */
    useEffect(() => {
        const sortedData = sortData(addCharges);
        const result = filterValidData(sortedData, rowProp);

        setSelectedId(rowProp?.id); // Same as movement_id.
        setChargeList(result?.charges);
        setProfileId(result?.profile_id);
    }, [rowProp, addCharges]);

    /** Handle to delete datagrid row. */
    const handleDeleteClick = (id) => () => {
        setAdditionalChargeRow(additionalChargeRow.filter((row) => row.id !== id));
    };

    /** Handle to update row with new values for changed parts. */
    const processRowUpdate = (newRow) => {
        if (!Number.isInteger(newRow.qty) || newRow.qty <= 0 || newRow.addcharge_rate < 0) {
            alert('QTY/Rate must be a positive integer.');
            return;
        }

        const matchedItem = chargeList.find((item) => item.id == newRow.charge_type);
        if (!matchedItem) return newRow;

        const { additional_charge_id, rate } = matchedItem;
        const isFixedCharge = [9].includes(additional_charge_id);
        const isFixedQTY = [7, 8, 10, 14].includes(additional_charge_id);
        const addcharge_rate = isFixedCharge ? newRow.addcharge_rate : rate;
        const updatedQty = isFixedQTY ? 1 : newRow.qty;

        const updatedRow = {
            ...newRow,
            movement_id: selectedId,
            profile_id: profileId,
            addcharge_rate,
            qty: updatedQty,
            total: addcharge_rate * newRow.qty,
            isNew: false,
        };

        setAdditionalChargeRow(additionalChargeRow.map((row) => (row.id === newRow.id ? updatedRow : row)));
        return updatedRow;
    };

    /** Handle to save addtional charge datagrid to DB. */
    const handleSaveBtn = () => {
        const saveData =
            additionalChargeRow.length > 0 ? additionalChargeRow.filter((item) => item.charge_type !== null) : lastDel;
        const isConfirmed = window.confirm(
            'Once confirmed, the amount for this movement will be settled. Are you sure all amounts are correct?'
        );
        if (!isConfirmed) return;
        apiRequest('manifest', 'save', userId, saveData)
            .then((response) => {
                if (response.statusCode === 200) {
                    setSnackbar({ open: true, message: response.body.message, severity: 'success' });
                    setInprogressRow((prev) => (prev ? prev.filter((item) => item.id !== rowProp.id) : []));
                } else {
                    setSnackbar({ open: true, message: response.body.message, severity: 'error' });
                }
            })
            .catch(() => {
                setSnackbar({ open: true, message: 'Something went wrong. Please try again.', severity: 'error' });
            });
    };

    /** Columns definition for additional charge datgrid. */
    const additionalChargeCol = additionalChargeCols(chargeList, handleDeleteClick, editable);

    return (
        <Stack sx={{ py: 3, height: '100%', boxSizing: 'border-box' }} direction="column">
            <Paper sx={{ flex: 1, mx: 'auto', width: '92%', p: 1, pb: 2 }}>
                <Stack direction="column" spacing={1}>
                    <Typography variant="h6" color="textSecondary">
                        Additional Charge Detail
                    </Typography>
                    <Grid container>
                        <Grid item xs={3}>
                            <Typography variant="body2">Name : {rowProp.driver}</Typography>
                        </Grid>
                        <Grid item xs={3}>
                            <Typography variant="body2">Container# : {rowProp.cntr}</Typography>
                        </Grid>
                        {active ? (
                            <Grid item xs={6} sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                                <Button
                                    sx={{
                                        ...customized_style.itemButtonRound,
                                        mb: 0,
                                        mr: 1,
                                    }}
                                    onClick={handleSaveBtn}
                                >
                                    Finalize Payment
                                </Button>
                            </Grid>
                        ) : (
                            <></>
                        )}
                    </Grid>
                    <DataGridPremium
                        editMode="cell"
                        rows={additionalChargeRow || []}
                        columns={additionalChargeCol}
                        density="compact"
                        hideFooter
                        unstable_cellSelection
                        disableRowSelectionOnClick
                        processRowUpdate={processRowUpdate}
                        slots={{
                            toolbar: EditToolbar,
                        }}
                        slotProps={{
                            toolbar: { setAdditionalChargeRow, editable },
                        }}
                        sx={{
                            '& .MuiDataGrid-cell': {
                                border: '1px solid #e9ecef',
                            },
                        }}
                    />
                </Stack>
            </Paper>
        </Stack>
    );
}
