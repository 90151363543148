import * as React from 'react';
import { useState, useEffect } from 'react';

/** Material UI Packages. */
import { Box, Grid, TextField, Typography } from '@mui/material';

/** Auth0 authenticator Packages. */
import { useAuth0 } from '@auth0/auth0-react';

/** Internal Components. */
import { customized_style } from '../../../theme/Styles';

export default function MyProfile() {
    /** Get user information from Auth0. */
    const { user } = useAuth0();

    /** State for personal information. */
    const [email, setEmail] = useState(null);
    const [firstName, setFirstName] = useState(null);
    const [lastName, setLastName] = useState(null);
    const [role, setRole] = useState(null);

    /** Set document title. */
    useEffect(() => {
        document.title = 'My Profile | Dispatch System';
    }, []);

    /** Initialize user information registered in Auth0 to the corresponding states. */
    useEffect(() => {
        const split_name = user.given_name.split(' ');
        setEmail(user.email);
        setFirstName(split_name[0]);
        setLastName(split_name[1]);
        setRole(user['https://drayage.insightshipping.io/roles'][0]);
    }, [user]);

    return (
        <>
            <Box sx={{ bgcolor: 'white', border: '1px solid #c0c0c0', borderRadius: 5, mx: 2, my: 4, p: 4 }}>
                <Grid container>
                    <Grid item xs={12}>
                        <Typography sx={{ color: '#00657e', fontSize: '2rem', fontWeight: 'bold', mb: 6 }}>
                            MY PROFILE
                        </Typography>
                    </Grid>
                    <Grid item xs={6}>
                        <Typography sx={customized_style.itemTypography}>INFORMATION</Typography>
                        <Grid item xs={12}>
                            <TextField
                                required
                                name="firstname"
                                id="user_firstname"
                                label="FIRST NAME"
                                defaultValue={!firstName ? '' : firstName}
                                key={!firstName ? 'firstname' : firstName}
                                variant="standard"
                                InputProps={{
                                    readOnly: true,
                                }}
                                sx={customized_style.itemTextfield}
                            />
                            <TextField
                                required
                                name="lastname"
                                id="user_lastname"
                                label="LAST NAME"
                                defaultValue={!lastName ? '' : lastName}
                                key={!lastName ? 'lastname' : lastName}
                                variant="standard"
                                InputProps={{
                                    readOnly: true,
                                }}
                                sx={customized_style.itemTextfield}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                required
                                name="useremail"
                                id="user_email"
                                label="EMAIL"
                                defaultValue={!email ? '' : email}
                                key={!email ? 'email' : email}
                                variant="standard"
                                InputProps={{
                                    readOnly: true,
                                }}
                                sx={customized_style.itemTextfield}
                            />
                            <TextField
                                required
                                name="userrole"
                                id="user_role"
                                label="ROLE"
                                defaultValue={!role ? '' : role}
                                key={!role ? 'role' : role}
                                variant="standard"
                                InputProps={{
                                    readOnly: true,
                                }}
                                sx={customized_style.itemTextfield}
                            />
                        </Grid>
                    </Grid>
                </Grid>
            </Box>
        </>
    );
}
