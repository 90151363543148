import * as React from 'react';
import { useState, useEffect } from 'react';

/** Material UI Packages. */
import { Box } from '@mui/material';
import { DataGridPremium } from '@mui/x-data-grid-premium';

/** Internal Components. */
import { dashboard_style } from '../../../theme/Styles';

/** Date format for datagrid. */
const date_format = { year: '2-digit', month: '2-digit', day: '2-digit' };

/** Generic DataGrid Table Component */
function DataGridTable({ data, dateField, columns, sortField }) {
    const [tableData, setTableData] = useState([]);
    const [sortModel, setSortModel] = useState([{ field: sortField, sort: 'asc' }]);

    /** Hook to format date field and udpate table data. */
    useEffect(() => {
        const formattedData = data.map((item) => ({
            ...item,
            [dateField]: new Date(item[dateField]).toLocaleDateString('en-US', date_format),
        }));
        setTableData(formattedData);
    }, [data, dateField]);

    return (
        <Box
            sx={{
                height: '77vh',
                width: '100%',
                '& .super-app-theme--header': {
                    color: '#ff4d6d',
                },
                '& .super-app-cell--header': {
                    fontWeight: 'bold',
                },
            }}
        >
            <DataGridPremium
                rows={tableData}
                columns={columns}
                sortModel={sortModel}
                density="compact"
                disableRowSelectionOnClick
                disableColumnMenu
                hideFooter
                sx={dashboard_style.itemDatagrid}
                initialState={{
                    columns: {
                        columnVisibilityModel: {
                            id: false,
                        },
                    },
                }}
            />
        </Box>
    );
}

/** Dashboard Demurrage Table */
export function DashboardDemTable({ demData }) {
    /** Define table columns for Demurrage data */
    const demColumns = [
        {
            field: 'id',
            headerName: 'ID',
            width: 50,
            align: 'center',
            headerAlign: 'center',
            sortable: false,
            hide: true,
        },
        { field: 'client', headerName: 'Client', width: 130, align: 'center', headerAlign: 'center', sortable: false },
        { field: 'cntr', headerName: 'CNTR#', width: 130, align: 'center', headerAlign: 'center', sortable: false },
        {
            field: 'dem_date',
            headerName: 'DEM Date',
            headerClassName: 'super-app-theme--header',
            cellClassName: 'super-app-cell--header',
            type: 'date',
            width: 140,
            align: 'center',
            headerAlign: 'center',
            valueFormatter: (params) => new Date(params.value).toLocaleDateString('en-US', date_format),
            hideable: false,
            pinnable: false,
        },
    ];

    return <DataGridTable data={demData} dateField="dem_date" columns={demColumns} sortField="dem_date" />;
}

/** Dashboard PerDiem Table */
export function DashboardPdiemTable({ perdiemData }) {
    /** Define table columns for PerDiem data */
    const pdiemColumns = [
        {
            field: 'id',
            headerName: 'ID',
            width: 50,
            align: 'center',
            headerAlign: 'center',
            sortable: false,
            hide: true,
        },
        { field: 'client', headerName: 'Client', width: 130, align: 'center', headerAlign: 'center', sortable: false },
        { field: 'cntr', headerName: 'CNTR#', width: 130, align: 'center', headerAlign: 'center', sortable: false },
        {
            field: 'pdiem_date',
            headerName: 'P.Diem Date',
            headerClassName: 'super-app-theme--header',
            cellClassName: 'super-app-cell--header',
            width: 140,
            align: 'center',
            headerAlign: 'center',
            valueFormatter: (params) => new Date(params.value).toLocaleDateString('en-US', date_format),
            hideable: false,
            pinnable: false,
        },
    ];

    return <DataGridTable data={perdiemData} dateField="pdiem_date" columns={pdiemColumns} sortField="pdiem_date" />;
}
