import * as React from 'react';
import { useState, useEffect } from 'react';

/** Material UI Packages. */
import { Box, Divider, Typography, Button } from '@mui/material';

/** Internal Components. */
import useApiRequest from '../../useApiRequest';

export default function ManifestCount(props) {
    /** Get props from App.js.  */
    const { userId } = props;
    /** Get props from Manifest.js.  */
    const { setDetailLoaded } = props;
    /** Get props from ManifestAssigned.js.  */
    const { count, setCount, setInprogressRow, setCompleteRow, active, setActive } = props;

    /** Custom API Request function */
    const apiRequest = useApiRequest();

    /** When status is true, completeQTY should be added up. */
    const [completeQTY, setCompleteQTY] = useState(null);
    /** Rest Movement QTY except for complete QTY. */
    const [inprogressQTY, setInprogressQTY] = useState(null);

    useEffect(() => {
        setInprogressQTY(count.inprogress_len);
        setCompleteQTY(count.complete_len);
    }, [count]);

    /** Handler for fetching inprogress / completed movements */
    const fetchMovements = (type, searchValue = []) => {
        setActive(type === 'inprogress');
        setDetailLoaded(false);
        apiRequest('manifest', type, userId, searchValue)
            .then((response) => {
                if (response.statusCode === 200) {
                    type === 'inprogress'
                        ? setInprogressRow(response.body.allMovementRow)
                        : setCompleteRow(response.body.allMovementRow);
                    setCount(response.body.count);
                }
            })
            .catch((error) => {
                console.error(`Error fetching ${type} movements:`, error);
            })
            .finally(() => {
                setDetailLoaded(true);
            });
    };

    /** When the 'In Progress' button is clicked, the fetching method is called. */
    const handleInprogress = () => fetchMovements('inprogress');

    /** When the 'Complete' button is clicked, the fetching method is called. */
    const handleComplete = () => {
        const storedSearchValue = sessionStorage.getItem('searchValue')
            ? JSON.parse(sessionStorage.getItem('searchValue'))
            : [];
        fetchMovements('search', storedSearchValue);
    };

    return (
        <Box
            sx={{
                display: 'flex',
                m: 0,
                color: 'text.secondary',
            }}
        >
            <Button onClick={handleInprogress} sx={{ p: 0 }}>
                <Typography
                    sx={{
                        color: active ? '#003a52' : '#00647e',
                        mx: 1,
                        fontSize: '1.2rem',
                        fontWeight: active ? 'bold' : 'regular',
                    }}
                >
                    IN PROGRESS : {inprogressQTY}
                </Typography>
            </Button>
            <Divider orientation="vertical" flexItem />
            <Button onClick={handleComplete} sx={{ p: 0 }}>
                <Typography
                    sx={{
                        color: '#00647e',
                        mx: 1,
                        fontSize: '1.2rem',
                        color: active ? '#00647e' : '#003a52',
                        fontWeight: active ? 'regular' : 'bold',
                    }}
                >
                    COMPLETE : {completeQTY}
                </Typography>
            </Button>
        </Box>
    );
}
