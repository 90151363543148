import * as React from 'react';
import { useState, useEffect } from 'react';

/** Material UI Packages. */
import { Typography, Stack, Paper } from '@mui/material';
import { DataGridPremium } from '@mui/x-data-grid-premium';

/** Internal Components. */
import { movement_col } from './TableDefinition';

export default function HistorySubTable({ row: rowProp }) {
    /** State for movement detail datagrid row. */
    const [movementRow, setMovementRow] = useState([]);

    /** Initialize the movement detail dategrid rows. */
    useEffect(() => {
        setMovementRow(rowProp.movement);
    }, [rowProp]);

    return (
        <Stack sx={{ py: 2, height: '100%', boxSizing: 'border-box' }} direction="column">
            <Paper sx={{ flex: 1, mx: 'auto', width: '99%', p: 1, pb: 2 }}>
                <Stack direction="column" spacing={1} sx={{ height: 1 }}>
                    <Typography variant="h6" color="textSecondary">
                        Movement Detail
                    </Typography>
                    <DataGridPremium
                        rows={movementRow || []}
                        columns={movement_col}
                        density="compact"
                        hideFooter
                        unstable_cellSelection
                        disableRowSelectionOnClick
                        sx={{ fontSize: '0.8rem', width: '1450px' }}
                    />
                </Stack>
            </Paper>
        </Stack>
    );
}
