import * as React from 'react';
import { useState, useEffect, useCallback } from 'react';

/** Material UI Packages. */
import { Box, Button, Grid, Paper, CircularProgress } from '@mui/material';
import { DataGridPremium } from '@mui/x-data-grid-premium';

/** Material UI Icons. */
import FindInPageIcon from '@mui/icons-material/FindInPage';

/** Auth0 authenticator Packages. */
import { useAuth0 } from '@auth0/auth0-react';

/** HTTP Client Liabrary. */
import axios from 'axios';

/** Internal Components. */
import useApiRequest from '../../useApiRequest';
import { CustomSkeletonVer2 } from '../../CustomSkeleton';
import { customized_style } from '../../../theme/Styles';
import { CustomExportToolbar } from '../../CustomExport';
import HistoryTimeline from './HistoryTimeline';
import HistorySearch from './HistorySearch';
import HistorySubTable from './HistorySubTable';
import { allHistoryCol } from './TableDefinition';

export default function History(props) {
    /** Get props from App.js.  */
    const { userId } = props;

    /** Redirect user if the token is invalid */
    const { getAccessTokenSilently } = useAuth0();

    /** Custom API Request function */
    const apiRequest = useApiRequest();

    /** State to display loading component until API response is received. */
    const [loaded, setLoaded] = useState(false);
    const [subLoaded, setSubLoaded] = useState(true);
    const [copiedData, setCopiedData] = useState('');

    /** Data for all history Datagird row. */
    const [allHistoryRow, setAllHistoryRow] = useState([]);
    const [driver, setDriver] = useState([]);
    const [timelineOpen, setTimelineOpen] = useState(false);

    /** State for managing the snackbar that displays the result of an API request. */
    const [snackbar, setSnackbar] = useState({ open: false, message: '', severity: 'success' });

    /** Set document title. */
    useEffect(() => {
        document.title = 'History | Dispatch System';
    }, []);

    /** Handle to open Timeline dialog. */
    const handleClickOpenTimeline = () => {
        setTimelineOpen(true);
    };

    /** Async API call to obtain dispatch - movement data. */
    useEffect(() => {
        (async () => {
            try {
                const token = await getAccessTokenSilently({
                    authorizationParams: {
                        audience:
                            'https://insightshippinginc.us.auth0.com/api/v2/' /** Include the audience of authorization parameters to authenticate API call. */,
                    },
                });
                const response = await axios.get('https://icxhzxkwxa.execute-api.us-west-1.amazonaws.com/api', {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                    params: {
                        type: 'history',
                        userId: userId,
                    },
                });
                const resp = response.data['body-json'];
                setAllHistoryRow(resp.body.history);
                setDriver(resp.body.driver);
                setLoaded(true);
            } catch (e) {
                throw e;
            }
        })();
    }, [getAccessTokenSilently]);

    /** Get the content of the detail panel for a row in the DataGrid. */
    const getDetailPanelContent = useCallback(
        ({ row }) => {
            if (row.movement && row.movement.length > 0) {
                return <HistorySubTable row={row} />;
            }
            return null;
        },
        [allHistoryRow]
    );

    /** Returns 'auto' to allow the panel to adjust its height based on the content. */
    const getDetailPanelHeight = useCallback(() => 'auto', []);

    /** Handler to process row updates. */
    // const processRowUpdate = (newRow, oldRow) => {
    //     apiRequest('history', 'status', userId, newRow)
    //         .then((response) => {
    //             if (response.statusCode === 200) {
    //                 setSnackbar({ open: true, message: response.body.message, severity: 'success' });
    //             } else {
    //                 setSnackbar({ open: true, message: response.body.message, severity: 'error' });
    //                 return oldRow;
    //             }
    //         })
    //         .catch((error) => {
    //             setSnackbar({ open: true, message: 'Something went wrong. Please try again.', severity: 'error' });
    //             return oldRow;
    //         });
    //     return newRow;
    // };
    const processRowUpdate = async (newRow, oldRow) => {
        try {
            const response = await apiRequest('history', 'status', userId, newRow);

            if (response.statusCode === 200) {
                setSnackbar({ open: true, message: response.body.message, severity: 'success' });
                return newRow; // 성공 시 newRow 유지
            } else {
                setSnackbar({ open: true, message: response.body.message, severity: 'error' });
                return oldRow; // 실패 시 oldRow 유지
            }
        } catch (error) {
            setSnackbar({ open: true, message: 'Something went wrong. Please try again.', severity: 'error' });
            return oldRow; // 에러 발생 시 oldRow 유지
        }
    };

    return (
        <>
            <Paper sx={{ ...customized_style.itemPaper_main, p: 2, pb: 0 }}>
                {loaded ? (
                    <>
                        <Grid container>
                            <Grid item sm={8} md={7.5}>
                                <HistorySearch
                                    userId={userId}
                                    setAllHistoryRow={setAllHistoryRow}
                                    setSubLoaded={setSubLoaded}
                                    snackbar={snackbar}
                                    setSnackbar={setSnackbar}
                                />
                            </Grid>
                            <Grid item sm={4} md={4.5}>
                                <Button sx={{ float: 'right' }} onClick={handleClickOpenTimeline}>
                                    <FindInPageIcon /> Timeline
                                </Button>
                            </Grid>
                        </Grid>
                        <Box>
                            {subLoaded ? (
                                <DataGridPremium
                                    rows={allHistoryRow}
                                    columns={allHistoryCol}
                                    editMode="row"
                                    density="compact"
                                    unstable_cellSelection
                                    disableRowSelectionOnClick
                                    processRowUpdate={processRowUpdate}
                                    onClipboardCopy={(copiedString) => setCopiedData(copiedString)}
                                    slots={{
                                        toolbar: CustomExportToolbar,
                                    }}
                                    sx={{ mx: 1, border: 'none', height: '80vh', fontSize: '12px' }}
                                    slotProps={{
                                        toolbar: {
                                            showQuickFilter: true,
                                        },
                                    }}
                                    getDetailPanelHeight={getDetailPanelHeight}
                                    getDetailPanelContent={getDetailPanelContent}
                                />
                            ) : (
                                <Box
                                    display="flex"
                                    justifyContent="center"
                                    alignItems="center"
                                    sx={{ width: '100%', height: '89vh' }}
                                >
                                    <CircularProgress />
                                </Box>
                            )}
                        </Box>
                    </>
                ) : (
                    <CustomSkeletonVer2 />
                )}
                <HistoryTimeline
                    userId={userId}
                    driver={driver}
                    timelineOpen={timelineOpen}
                    setTimelineOpen={setTimelineOpen}
                    snackbar={snackbar}
                    setSnackbar={setSnackbar}
                />
            </Paper>
        </>
    );
}
