import { useState, useEffect, useRef } from 'react';

/** HTTP Client Library. */
import axios from 'axios';

/** Auth0 authenticator Packages. */
import { useAuth0 } from '@auth0/auth0-react';

/** Internal Components. */
import useApiRequest from '../../../useApiRequest';

const useScheduleTable = (userId, type) => {
    /** Call custom context provider to determine the user legitimacy */
    const { getAccessTokenSilently } = useAuth0();
    /** Custom API Request function */
    const apiRequest = useApiRequest();

    /** State for managing the snackbar that displays the result of an API request. */
    const [snackbar, setSnackbar] = useState({ open: false, message: '', severity: 'success' });

    /** State for datagrid row.  */
    const [rows, setRows] = useState([]);
    const [rowOrder, setRowOrder] = useState([]);
    /** State to display loading component until API response is received. */
    const [loaded, setLoaded] = useState(false);
    /** State to manage the disabled state of the button. */
    const [disableBtn, setDisableBtn] = useState(true);

    /** Set document title. */
    useEffect(() => {
        document.title = `${type === 'loadOrder' ? 'Load D/O' : 'Empty D/O'} - Insight Logistics | Dispatch `;
    }, [type]);

    /** Async API call to obtain Load D/O & Empty D/O data. */
    useEffect(() => {
        (async () => {
            try {
                const token = await getAccessTokenSilently({
                    authorizationParams: {
                        audience: 'https://insightshippinginc.us.auth0.com/api/v2/',
                    },
                });
                const response = await axios.get('https://icxhzxkwxa.execute-api.us-west-1.amazonaws.com/api', {
                    headers: { Authorization: `Bearer ${token}` },
                    params: { type: type, userId: userId, test: true },
                });
                const resp = response.data['body-json'];
                setRows(resp.body.dispatch);
                setRowOrder(resp.body.row_order);
                setLoaded(true);
            } catch (e) {}
        })();
    }, [getAccessTokenSilently, type, userId]);

    useEffect(() => {
        if (rowOrder) {
            const orderMap = new Map(rowOrder.map((item) => [item.do_id, item.row_id]));
            const sorted_rows = [...rows].sort((a, b) => {
                const orderA = orderMap.has(a.id) ? orderMap.get(a.id) : Infinity;
                const orderB = orderMap.has(b.id) ? orderMap.get(b.id) : Infinity;
                return orderA - orderB;
            });
            setRows(sorted_rows);
        }
    }, [rowOrder]);

    /** Asynchronously update the position of rows. */
    const handleRowOrderChange = async (params) => {
        const newRows = await updateRowPosition(params.oldIndex, params.targetIndex, rows);
        setRows(newRows);
        setDisableBtn(false);
    };

    /** Handle to save row ordering. */
    const handleSave = () => {
        const orderingData = [...rows].map((item, index) => ({ row_id: index + 1, do_id: item.id }));
        setDisableBtn(true);
        apiRequest(type, 'order_by', userId, orderingData)
            .then((response) => {
                if (response.statusCode === 200) {
                    setSnackbar({ open: true, message: response.body.message, severity: 'success' });
                } else {
                    setSnackbar({ open: true, message: response.body.message, severity: 'error' });
                }
            })
            .catch((error) => {
                setSnackbar({ open: true, message: 'Something went wrong. Please try again.', severity: 'error' });
            });
    };

    /** Refresh schedule data on button click. */
    const handleRefresh = () => {
        setLoaded(false);
        apiRequest(type, 'refresh', userId, [])
            .then((response) => {
                if (response.statusCode === 200) {
                    setSnackbar({ open: true, message: response.body.message, severity: 'success' });
                    setRows(response.body.data);
                    setRowOrder(response.body.row_order);
                    setLoaded(true);
                } else {
                    setSnackbar({ open: true, message: response.body.message, severity: 'error' });
                }
            })
            .catch(() => {
                setSnackbar({ open: true, message: 'Something went wrong. Please try again.', severity: 'error' });
            });
    };

    return {
        rows,
        loaded,
        disableBtn,
        handleRowOrderChange,
        handleSave,
        handleRefresh,
        buttonRef: useRef(null),
        snackbar,
        setSnackbar,
    };
};

/**
 * Function to update the position of a row in an array of rows.
 *
 * @param {number} initialIndex - The initial index of the row to be moved.
 * @param {number} newIndex - The new index where the row should be moved to.
 * @param {Array} rows - The array of rows.
 * @returns {Promise<Array>} A promise that resolves to the new array of rows with the row moved to the new position.
 */
const updateRowPosition = (initialIndex, newIndex, rows) => {
    return new Promise((resolve) => {
        setTimeout(() => {
            const rowsClone = [...rows];
            const row = rowsClone.splice(initialIndex, 1)[0];
            rowsClone.splice(newIndex, 0, row);
            resolve(rowsClone);
        }, Math.random() * 500 + 100); // Random delay between 100 and 600 milliseconds
    });
};

export default useScheduleTable;
