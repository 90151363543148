import * as React from 'react';
import { Fragment, useState, useEffect } from 'react';

/** Material UI Packages. */
import { Typography, ListItemButton, ListItemIcon, ListSubheader, Divider } from '@mui/material';

/** Material UI Icons. */
import DashboardIcon from '@mui/icons-material/Dashboard';
import PersonAddAlt1Icon from '@mui/icons-material/PersonAddAlt1';
import BarChartIcon from '@mui/icons-material/BarChart';
import WorkHistoryIcon from '@mui/icons-material/WorkHistory';
import LocalShippingIcon from '@mui/icons-material/LocalShipping';
import MonetizationOnIcon from '@mui/icons-material/MonetizationOn';
import ManageSearchIcon from '@mui/icons-material/ManageSearch';
import TaskIcon from '@mui/icons-material/Task';
import PointOfSaleIcon from '@mui/icons-material/PointOfSale';

/** Client-side routing Library.  */
import { Link, useLocation, useNavigate } from 'react-router-dom';

/** Internal Components. */
import { menubar_style } from './Styles';

export default function MenuListItems(props) {
    /** Get props from App.js.  */
    const { userRole } = props;

    /** Navigator && Location through react-router-dom */
    const location = useLocation();
    const navigate = useNavigate();

    /** State to manage the selected index. */
    const [selectedIndex, setSelectedIndex] = useState(null);
    /** Create state-based menu items */
    const [menuItems, setMenuItems] = useState([]);

    /** Handler to change seletedIndex and navigate the correponding page.  */
    const handleListItemClick = (index) => {
        if (userRole.includes('Dispatch-Staff')) {
            if ([5, 6, 7, 8].includes(index)) {
                setSelectedIndex(0);
                return navigate('/dashboard');
            } else if (userRole.includes('Dispatch-Manager')) {
                if (index === 8) {
                    setSelectedIndex(0);
                    return navigate('/dashboard');
                }
            }
            setSelectedIndex(index);
        }
    };

    /** When loading a new page, selectedIndex is changed to the sessionStorage value to prevent the state from being initialized. */
    useEffect(() => {
        const getPathIndex = () => {
            let pathIndex = null;
            switch (location.pathname) {
                case '/dashboard':
                    return (pathIndex = 0);
                case '/deliveryorder':
                    return (pathIndex = 1);
                case '/schedule':
                    return (pathIndex = 2);
                case '/manifest':
                    return (pathIndex = 3);
                case '/history':
                    return (pathIndex = 4);
                case '/dataentry':
                    return (pathIndex = 5);
                case '/rate':
                    return (pathIndex = 6);
                case '/payroll':
                    return (pathIndex = 7);
                case '/logs':
                    return (pathIndex = 8);
            }
            return pathIndex;
        };
        setSelectedIndex(getPathIndex());
    }, [location.pathname]);

    /** Change the number of available menu items depending on the user's role.
     * Note that you MUST define the menu items within the hook.
     * Otherwise, the original menu items can be modified and won't be reflected on the DOM. */
    useEffect(() => {
        const getMenus = (props) => {
            const menu = [
                { index: 0, to: '/dashboard', icon: <DashboardIcon />, text: 'DASHBOARD' },
                { index: 1, to: '/deliveryorder', icon: <TaskIcon />, text: 'DELIVERY ORDER' },
                { index: 2, to: '/schedule', icon: <LocalShippingIcon />, text: 'SCHEDULE' },
                { index: 3, to: '/manifest', icon: <PointOfSaleIcon />, text: 'MANIFEST' },
                { index: 4, to: '/history', icon: <ManageSearchIcon />, text: 'HISTORY' },
                { index: 5, to: '/dataentry', icon: <PersonAddAlt1Icon />, text: 'DATA ENTRY' },
                { index: 6, to: '/rate', icon: <BarChartIcon />, text: 'RATE' },
                { index: 7, to: '/payroll', icon: <MonetizationOnIcon />, text: 'PAYROLL' },
                { index: 8, to: '/logs', icon: <WorkHistoryIcon />, text: 'LOGS' },
            ];
            if (props.includes('Dispatch-Staff')) {
                return menu.filter((item) => ![5, 6, 7, 8].includes(item.index));
            } else if (props.includes('Dispatch-Manager')) {
                return menu.filter((item) => item.index !== 8);
            } else {
                return menu;
            }
        };
        setMenuItems(getMenus(userRole));
    }, [userRole]);

    return (
        <>
            <ListSubheader component="div" inset></ListSubheader>
            {menuItems.map((item) => (
                <Fragment key={item.index}>
                    <ListItemButton
                        selected={selectedIndex == item.index}
                        onClick={() => handleListItemClick(item.index)}
                        component={Link}
                        to={item.to}
                        sx={menubar_style.itemButtons}
                    >
                        <ListItemIcon>{item.icon}</ListItemIcon>
                        <Typography sx={menubar_style.itemTypography}>{item.text}</Typography>
                    </ListItemButton>
                    {item.index == 4 || item.index == 7 ? <Divider sx={{ my: 1 }} /> : null}
                </Fragment>
            ))}
        </>
    );
}
