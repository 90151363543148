import * as React from 'react';
import { useEffect } from 'react';

/** Material UI Packages. */
import { Avatar, Button, CssBaseline, Box, Typography, createTheme, ThemeProvider, useMediaQuery } from '@mui/material';

/** Auth0 authenticator Packages. */
import { useAuth0 } from '@auth0/auth0-react';

/** Router packages. */
import { useNavigate } from 'react-router-dom';

/** Internal Components */
import insight_logo from '../statics/images/insight_logo_login.png';
import login from '../statics/images/landscape_login.png';
import Copyright from '../components/functions/CopyRight';

const defaultTheme = createTheme();

export default function Login() {
    /** Destructure necessary properties from Auth0. */
    const { isAuthenticated, user, loginWithRedirect } = useAuth0();

    /** Get the navigation function from React Router. */
    const navigate = useNavigate();

    /** Detecting window size for responsive web. */
    const isSmallScreen = useMediaQuery('(max-width: 600px)');
    const isMediumScreen = useMediaQuery('(max-width: 1024px)');

    /** Catch if the user is authenticated and has an active state of permission role.
     * * Redirect authenticated users based on their roles.
     * - VIP/Customer: navigate to /track
     * - Staff: navigate to /warehouse
     * - Manager/Administrator: navigate to /manager
     */
    useEffect(() => {
        if (isAuthenticated && user) {
            return navigate('/');
        }
    }, [isAuthenticated, user]);

    return (
        <ThemeProvider theme={defaultTheme}>
            <CssBaseline />
            <Box
                sx={{
                    backgroundImage: `url(${login})`,
                    backgroundRepeat: 'no-repeat',
                    backgroundColor: (t) => (t.palette.mode === 'light' ? t.palette.grey[50] : t.palette.grey[900]),
                    backgroundSize: 'cover',
                    backgroundPosition: 'center',
                    position: 'relative',
                    zIndex: 1,
                    width: '100%',
                    height: '100vh',
                }}
            >
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        justifyContent: 'center',
                        position: 'absolute',
                        top: '50%',
                        left: '50%',
                        transform: 'translate(-50%, -50%)',
                        zIndex: 2,
                        width: isSmallScreen ? '80%' : isMediumScreen ? '70%' : '40%',
                        height: '50vh',
                        m: '0 auto',
                        borderRadius: 5,
                        bgcolor: '#ffffff',
                        opacity: 0.85,
                    }}
                >
                    <Avatar
                        alt="logo"
                        src={insight_logo}
                        sx={{ width: isSmallScreen ? 130 : 150, height: isSmallScreen ? 130 : 150, p: 3, opacity: 1 }}
                    ></Avatar>
                    <Typography
                        sx={{
                            fontWeight: 'bold',
                            mt: isSmallScreen ? '5%' : '8%',
                            fontSize: isSmallScreen ? '1.5rem' : '1.8rem',
                        }}
                    >
                        Welcome back!
                    </Typography>
                    <Box
                        component="form"
                        onSubmit={loginWithRedirect}
                        noValidate
                        sx={{ mt: 1, width: '100%', display: 'flex', justifyContent: 'center' }}
                    >
                        <Button
                            type="submit"
                            sx={{
                                width: isSmallScreen ? '70%' : isMediumScreen ? '30%' : '30%',
                                height: '5vh',
                                bgcolor: '#00647e',
                                color: 'white',
                                mt: isMediumScreen ? 5 : 3,
                                fontWeight: 'bold',
                                borderRadius: 5,
                                backgroundColor: '#00657e',
                                ':hover': { backgroundColor: '#003a52' },
                            }}
                        >
                            Get Started
                        </Button>
                    </Box>
                </Box>
                <Copyright />
            </Box>
        </ThemeProvider>
    );
}
