import * as React from 'react';

/** Material UI Packages. */
import { Snackbar, Alert } from '@mui/material';

export default function CustomSnackbar(props) {
    /** Receiving props from each componenets. */
    const { snackbar, setSnackbar } = props;

    /** Handle to close the snackbar. */
    const handleAlertClose = (event, reason) => {
        // Do nothing if the click is outside the snackbar.
        if (reason === 'clickaway') {
            return;
        }
        setSnackbar((prev) => ({
            ...prev,
            open: false,
        }));
    };
    return (
        <Snackbar open={snackbar.open} autoHideDuration={2000} onClose={handleAlertClose}>
            <Alert onClose={handleAlertClose} severity={snackbar.severity} sx={{ width: '100%' }}>
                {snackbar.message}
            </Alert>
        </Snackbar>
    );
}
