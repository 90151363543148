import * as React from 'react';
import { useState, useEffect, useCallback } from 'react';

/** Material UI Packages. */
import { Box, CircularProgress } from '@mui/material';
import { DataGridPremium } from '@mui/x-data-grid-premium';

/** Date Format Library. */
import dayjs from 'dayjs';

/** Internal Components. */
import CustomSnackbar from '../../CustomSnackbar';
import useApiRequest from '../../useApiRequest';
import { CustomExportToolbar } from '../../CustomExport';
import ManifestSearch from './ManifestSearch';
import { ExtraChargeList } from './ExtraChargeList';
import { movements_col } from './TableDefinition';

export default function ManifestAssigned(props) {
    /** Get props from App.js. */
    const { userId } = props;
    /** Get props from Manifest.js. */
    const {
        addCharges,
        inprogressRow,
        setInprogressRow,
        completeRow,
        setCompleteRow,
        active,
        count,
        detailLoaded,
        setDetailLoaded,
    } = props;

    /** Custom API Request function */
    const apiRequest = useApiRequest();

    /** State for managing the snackbar that displays the result of an API request. */
    const [snackbar, setSnackbar] = useState({ open: false, message: '', severity: 'success' });

    /** Callback to get the detail panel content for rows. */
    const getDetailPanelContent = useCallback(
        ({ row }) => {
            if (row.status == 'Completed' || row.status == 'Failed') {
                return (
                    <ExtraChargeList
                        userId={userId}
                        rowProp={row}
                        addCharges={addCharges}
                        setSnackbar={setSnackbar}
                        active={active}
                        setInprogressRow={setInprogressRow}
                    />
                );
            }
        },
        [active]
    );

    /** Callback to get the height for detail panel. */
    const getDetailPanelHeight = useCallback(() => 'auto', []);

    /** Effect to set move date format for datagrid based on active state. */
    useEffect(() => {
        const updateRowDates = (rows) => rows.map((item) => ({ ...item, move_date: dayjs(item.move_date) }));
        if (active) {
            setInprogressRow(updateRowDates(inprogressRow));
        } else {
            setCompleteRow(updateRowDates(completeRow));
        }
    }, [count]);

    /**
     * Check if status field is editable.
     * Cannot edit the confirmed column when the movement status is "In Progress".
     */
    const isStatusEditable = (params) => {
        if (params.row.status == 'In Progress') {
            return false;
        } else return true;
    };

    /** Handler to process row updates. */
    const processRowUpdate = (newRow) => {
        const updatedRow = { ...newRow, isNew: false };
        /** POST request to DB */
        apiRequest('manifest', 'status', userId, updatedRow)
            .then((response) => {
                if (response.statusCode === 200) {
                    setSnackbar({ open: true, message: response.body.message, severity: 'success' });
                    setCompleteRow((prev) => (prev ? prev.filter((item) => item.id !== newRow.id) : []));
                } else {
                    setSnackbar({ open: true, message: response.body.message, severity: 'error' });
                }
            })
            .catch((error) => {
                setSnackbar({ open: true, message: 'Something went wrong. Please try again.', severity: 'error' });
            });

        /** Update the datagrid row in the state. */
        if (active) {
            setInprogressRow(inprogressRow.map((row) => (row.id === newRow.id ? updatedRow : row)));
        } else {
            setCompleteRow(completeRow.map((row) => (row.id === newRow.id ? updatedRow : row)));
        }
        return updatedRow;
    };

    const movementCol = movements_col(active, false);

    return (
        <>
            <Box
                sx={{
                    height: active ? 630 : 520,
                    '& .editable-cell': {
                        color: '#e36414',
                    },
                }}
            >
                {active ? (
                    <></>
                ) : (
                    <ManifestSearch
                        userId={userId}
                        inprogressRow={inprogressRow}
                        setInprogressRow={setInprogressRow}
                        completeRow={completeRow}
                        setCompleteRow={setCompleteRow}
                        active={active}
                        setDetailLoaded={setDetailLoaded}
                        setSnackbar={setSnackbar}
                    />
                )}
                {detailLoaded ? (
                    <DataGridPremium
                        rows={active ? inprogressRow : completeRow}
                        columns={movementCol}
                        density="compact"
                        unstable_cellSelection
                        disableRowSelectionOnClick
                        processRowUpdate={processRowUpdate}
                        slots={{
                            toolbar: CustomExportToolbar,
                        }}
                        slotProps={{
                            toolbar: {
                                showQuickFilter: true,
                            },
                        }}
                        sx={{ border: 'none', fontSize: '12px', height: active ? '79vh' : '70vh' }}
                        getDetailPanelHeight={getDetailPanelHeight}
                        getDetailPanelContent={getDetailPanelContent}
                        isCellEditable={(params) => isStatusEditable(params)}
                        disableVirtualization
                    />
                ) : (
                    <Box display="flex" justifyContent="center" alignItems="center" sx={{ height: '100%' }}>
                        <CircularProgress />
                    </Box>
                )}
                <CustomSnackbar snackbar={snackbar} setSnackbar={setSnackbar} />
            </Box>
        </>
    );
}
