import * as React from 'react';

/** Material UI Packages. */
import { Box, Button } from '@mui/material';
import { randomId } from '@mui/x-data-grid-generator';
import { GridRowModes, GridToolbarContainer, GridToolbarQuickFilter } from '@mui/x-data-grid-premium';

/** Material UI Icons. */
import AddIcon from '@mui/icons-material/Add';
import SaveIcon from '@mui/icons-material/Save';

/**
 * @brief Handler to create a new row into datagrid & Quick filter(searchbar).
 * @param {setDoRow,setRowModesModel} props from NewContainer
 * @returns setDoRow
 */
export default function CustomToolbar(props) {
    const {
        doRow,
        setDoRow,
        setRowModesModel,
        saveDisableBtn,
        setSaveDisableBtn,
        apiRequest,
        userId,
        setDispatch,
        setSnackbar,
    } = props;
    const newRowhandleClick = () => {
        const id = randomId();
        setDoRow((oldRows) => [
            {
                id,
                dispatch_type: true,
                team: '',
                client: '',
                mbl: '',
                cntr: '',
                size: '',
                terminal: '',
                vessel: '',
                eta: '',
                etb: '',
                dem: '',
                appt: null,
                obl: false,
                customs: false,
                pierpass: false,
                remark: '',
                link: '',
                isNew: true,
            },
            ...oldRows,
        ]);
        setRowModesModel((oldModel) => ({
            ...oldModel,
            [id]: { mode: GridRowModes.Edit, fieldToFocus: 'team' },
        }));
        setSaveDisableBtn(false);
    };
    const handleSaveClick = () => {
        const updatedRow = [...doRow].filter((item) => item.isNew == true);
        apiRequest('deliveryorder', 'dispatch', userId, updatedRow)
            .then((response) => {
                if (response.statusCode === 200) {
                    setDispatch(response.body.data);
                    setSnackbar({ open: true, message: response.body.message, severity: 'success' });
                } else {
                    setSnackbar({ open: true, message: response.body.message, severity: 'error' });
                }
            })
            .catch((error) => {
                setSnackbar({ open: true, message: 'Something went wrong. Please try again.', severity: 'error' });
            });
        setSaveDisableBtn(true);
    };
    return (
        <GridToolbarContainer component="div">
            <Box width="100%" display="flex" flexDirection="row" justifyContent="center">
                <Box width="50%" display="flex" justifyContent="flex-start">
                    <Button
                        color="primary"
                        startIcon={<AddIcon />}
                        onClick={newRowhandleClick}
                        sx={{ color: '#00647e', fontWeight: '700' }}
                    >
                        Add Container
                    </Button>
                    <Button startIcon={<SaveIcon />} onClick={handleSaveClick} disabled={saveDisableBtn}>
                        Save
                    </Button>
                </Box>
                <Box width="50%" display="flex" justifyContent="flex-end">
                    <GridToolbarQuickFilter />
                </Box>
            </Box>
        </GridToolbarContainer>
    );
}
