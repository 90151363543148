import * as React from 'react';
import { useState, useEffect } from 'react';

/** Material UI Packages. */
import {
    FormControl,
    TextField,
    FormLabel,
    RadioGroup,
    FormControlLabel,
    Radio,
    Button,
    Grid,
    Divider,
    Stack,
    Box,
} from '@mui/material';
import { GridActionsCellItem } from '@mui/x-data-grid-pro';
import { DataGridPremium, GridRowEditStopReasons, GridRowModes } from '@mui/x-data-grid-premium';

/** Material UI Icons. */
import PersonAddAltIcon from '@mui/icons-material/PersonAddAlt';
import EditIcon from '@mui/icons-material/Edit';
import SaveIcon from '@mui/icons-material/Save';
import CancelIcon from '@mui/icons-material/Close';

/** Internal Components. */
import CustomSnackbar from '../../CustomSnackbar';
import useApiRequest from '../../useApiRequest';
import { customized_style } from '../../../theme/Styles';
import { CustomExportToolbar } from '../../CustomExport';

export default function DriverEntry(props) {
    /** Get props from App.js.  */
    const { userId } = props;
    /** Get props from DataEntry.js.  */
    const { driver, setDriver } = props;

    /** Custom API context */
    const apiRequest = useApiRequest();

    /** State for managing the snackbar that displays the result of an API request. */
    const [snackbar, setSnackbar] = useState({ open: false, message: '', severity: 'success' });

    /** Driver Info Datagrid row. */
    const [driverRow, setDriverRow] = useState([]);
    const [driverType, setDriverType] = useState(true);
    /** When double-click, the row will be activated on Datagrid. */
    const [rowModesModel, setRowModesModel] = useState({});

    /** Populate Driver info through API. */
    useEffect(() => {
        setDriverRow(driver);
    }, [driver]);

    /** New driver info is added using submit button on Datagrid */
    const addNewDriver = (event) => {
        event.preventDefault(); // To prevent Datagrid re-rendering.
        const form_data = new FormData(event.target);
        const last_id = driverRow.length === 0 ? 0 : driverRow[driverRow.length - 1].id;
        const new_data = {
            id: parseInt(last_id) + 1,
            first_name: form_data.get('driver_firstname'),
            last_name: form_data.get('driver_lastname'),
            driver_type: driverType,
            status: true,
        };
        const new_Driver = [...driverRow, new_data];
        setDriverRow(new_Driver);
        apiRequest('dataentry', 'driver', userId, new_data)
            .then((response) => {
                if (response.statusCode === 200) {
                    setSnackbar({ open: true, message: response.body.message, severity: 'success' });
                    setDriver(response.body.data);
                } else {
                    setSnackbar({ open: true, message: response.body.message, severity: 'error' });
                }
            })
            .catch((error) => {
                setSnackbar({ open: true, message: 'Something went wrong. Please try again.', severity: 'error' });
            });
    };

    /** Deactivate edit row mode on Datagrid. */
    const handleRowEditStop = (params, event) => {
        if (params.reason === GridRowEditStopReasons.rowFocusOut) {
            event.defaultMuiPrevented = true;
        }
    };

    /** Datagrid Row edit handler for driver info. */
    const handleEditClick = (id) => () => {
        setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.Edit } });
    };

    /** Datagrid Row save handler for driver info.  */
    const handleSaveClick = (id) => () => {
        setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.View } });
    };

    /** rowModesModel state change to View mode. */
    const handleCancelClick = (id) => () => {
        setRowModesModel({
            ...rowModesModel,
            [id]: { mode: GridRowModes.View, ignoreModifications: true },
        });
        const editedRow = driverRow.find((row) => row.id === id);
        if (editedRow.isNew) {
            setDriverRow(driverRow.filter((row) => row.id !== id));
        }
    };

    /** Handler to update row with new values for changed parts. */
    const processRowUpdate = (newRow) => {
        const updatedRow = { ...newRow, isNew: false, driver_type: newRow.driver_type === 'Owner' ? false : true };
        const new_driverRow = [...driverRow].map((row) => {
            if (row.id === newRow.id) {
                row = updatedRow;
            }
            return row;
        });
        setDriverRow(new_driverRow);
        apiRequest('dataentry', 'driver', userId, updatedRow)
            .then((response) => {
                if (response.statusCode === 200) {
                    setSnackbar({ open: true, message: response.body.message, severity: 'success' });
                    setDriver(response.body.data);
                } else {
                    setSnackbar({ open: true, message: response.body.message, severity: 'error' });
                }
            })
            .catch((error) => {
                setSnackbar({ open: true, message: 'Oops, something went wrong :(', severity: 'error' });
            });
        return updatedRow;
    };

    /** When double click, activate Row to change. */
    const handleRowModesModelChange = (newRowModesModel) => {
        setRowModesModel(newRowModesModel);
    };

    /** Handler to change driver type value using radio button. */
    const handleDriverTypeChange = (event) => {
        const newValue = event.target.value === 'true';
        setDriverType(newValue);
    };

    /** Define driver Datagrid Fields. */
    const driver_col = [
        { field: 'id', headerName: 'ID', width: 50, align: 'center', headerAlign: 'center' },
        {
            field: 'first_name',
            headerName: 'First Name',
            width: 180,
            editable: true,
            align: 'center',
            headerAlign: 'center',
        },
        {
            field: 'last_name',
            headerName: 'Last Name',
            width: 100,
            editable: true,
            align: 'center',
            headerAlign: 'center',
        },
        {
            field: 'driver_type',
            headerName: 'Type',
            width: 150,
            editable: true,
            type: 'singleSelect',
            valueOptions: ['Company', 'Owner'],
            align: 'center',
            headerAlign: 'center',
            valueGetter: (params) => {
                if (params.row.driver_type) {
                    return 'Company';
                }
                return 'Owner';
            },
        },
        {
            field: 'status',
            headerName: 'Status',
            width: 100,
            editable: true,
            type: 'boolean',
            align: 'center',
            headerAlign: 'center',
        },
        {
            field: 'actions',
            type: 'actions',
            headerName: 'Actions',
            width: 130,
            cellClassName: 'actions',
            align: 'center',
            headerAlign: 'center',
            getActions: ({ id }) => {
                const isInEditMode = rowModesModel[id]?.mode === GridRowModes.Edit;

                if (isInEditMode) {
                    return [
                        <GridActionsCellItem
                            icon={<SaveIcon />}
                            label="Save"
                            sx={{
                                color: '#00647e',
                            }}
                            onClick={handleSaveClick(id)}
                        />,
                        <GridActionsCellItem
                            icon={<CancelIcon />}
                            label="Cancel"
                            className="textPrimary"
                            onClick={handleCancelClick(id)}
                            sx={{
                                color: '#00647e',
                            }}
                        />,
                    ];
                }

                return [
                    <GridActionsCellItem
                        icon={<EditIcon />}
                        label="Edit"
                        className="textPrimary"
                        onClick={handleEditClick(id)}
                        color="inherit"
                    />,
                ];
            },
        },
    ];
    return (
        <Grid container>
            <Grid item xs={4}>
                <Box component="form" onSubmit={addNewDriver}>
                    <TextField
                        required
                        fullWidth
                        id="driver_firstname"
                        label="First Name"
                        name="driver_firstname"
                        autoComplete="driver_firstname"
                    />
                    <TextField
                        required
                        fullWidth
                        id="driver_lastname"
                        label="Last Name"
                        name="driver_lastname"
                        autoComplete="driver_lastname"
                        sx={{ mt: 2 }}
                    />
                    <FormControl sx={{ mt: 2 }}>
                        <FormLabel id="driver_type">Type</FormLabel>
                        <RadioGroup row aria-labelledby="driver_type" defaultValue="Company" name="driver_type">
                            <FormControlLabel
                                value="true"
                                checked={driverType === true}
                                control={<Radio />}
                                onChange={handleDriverTypeChange}
                                label="Company"
                            />
                            <FormControlLabel
                                value="false"
                                checked={driverType === false}
                                control={<Radio />}
                                onChange={handleDriverTypeChange}
                                label="Owner"
                            />
                        </RadioGroup>
                    </FormControl>
                    <Stack direction="row" justifyContent="center">
                        <Button
                            type="submit"
                            variant="contained"
                            startIcon={<PersonAddAltIcon />}
                            sx={{ ...customized_style.itemButtons_add, width: '42%', mt: 3 }}
                        >
                            Add New Driver
                        </Button>
                        <CustomSnackbar snackbar={snackbar} setSnackbar={setSnackbar} />
                    </Stack>
                </Box>
            </Grid>
            <Divider orientation="vertical" flexItem sx={{ mx: 3 }} />
            <Grid item xs={7.5} sx={{ height: '77vh' }}>
                <DataGridPremium
                    rows={driverRow}
                    columns={driver_col}
                    editMode="row"
                    density="compact"
                    unstable_cellSelection
                    rowModesModel={rowModesModel}
                    onRowModesModelChange={handleRowModesModelChange}
                    onRowEditStop={handleRowEditStop}
                    processRowUpdate={processRowUpdate}
                    disableRowSelectionOnClick
                    sx={customized_style.itemDatagrid}
                    slots={{ toolbar: CustomExportToolbar }}
                    initialState={{
                        pagination: { paginationModel: { pageSize: 25 } },
                    }}
                    pagination={true}
                    pageSizeOptions={[25, 50, 100]}
                />
            </Grid>
        </Grid>
    );
}
