import * as React from 'react';
import { useState, useEffect, useCallback } from 'react';

/** Material UI Packages. */
import { Grid, Button, Box, CircularProgress } from '@mui/material';
import { DataGridPremium } from '@mui/x-data-grid-premium';

/** React Dater picker range Packages. */
import 'react-date-range/dist/styles.css';
import 'react-date-range/dist/theme/default.css';
import { DateRangePicker } from 'react-date-range';

/** Internal Components. */
import { customized_style } from '../../../theme/Styles';
import PayrollDataSub from './PayrollDataSub';
import useApiRequest from '../../useApiRequest';
import CustomSnackbar from '../../CustomSnackbar';
import { usdPrice } from '../../CustomFormat';
import { CustomExportToolbar } from '../../CustomExport';

import dayjs from 'dayjs';

export function YearlyPayroll(props) {
    /** Get props from Payroll.js.  */
    const { yearlyPayroll } = props;
    /** State for datagrid row. */
    const [yearlyPayrollRows, setYearlyPayrollRows] = useState([]);
    /** State for datagrid columns definition. */
    const [yearlyPayrollCol, setYearlyPayrollCol] = useState([]);
    /** State for storing the aggregation model. */
    const [aggregationModel, setAggregationModel] = useState({});

    /**
     * Define yearly payroll Datagrid Fields.
     * Update yearly payroll Datagrid row.
     */
    useEffect(() => {
        if (yearlyPayroll) {
            const allKeys = yearlyPayroll.flatMap((obj) => {
                return Object.keys(obj).filter((key) => key !== 'id' && key !== 'driver_name');
            });
            const uniqueKeys = [...new Set(allKeys)];
            const new_payrolls = [...uniqueKeys].map((item) => {
                return {
                    field: item.toString(),
                    headerName: item.toString(),
                    width: 100,
                    align: 'center',
                    headerAlign: 'center',
                    sortable: false,
                    ...usdPrice,
                };
            });
            const new_payrollsColDef = [
                { field: 'id', headerName: 'ID', width: 30, align: 'center', headerAlign: 'center', hide: true },
                { field: 'driver_name', headerName: 'Driver Name', width: 180, align: 'center', headerAlign: 'center' },
                ...new_payrolls,
            ];
            setYearlyPayrollCol(new_payrollsColDef);
            setYearlyPayrollRows(yearlyPayroll);
        }
    }, [yearlyPayroll]);

    /** Effect to update the aggregation model whenever the column definitions change. */
    useEffect(() => {
        setAggregationModel(defaultAggregationModel);
    }, [yearlyPayrollCol]);

    /** Generates the default aggregation model for numerical columns. */
    const defaultAggregationModel = Object.fromEntries(
        yearlyPayrollCol.filter((col) => col.type === 'number').map((col) => [col.field, 'sum'])
    );

    return (
        <DataGridPremium
            rows={yearlyPayrollRows}
            columns={yearlyPayrollCol}
            density="compact"
            disableRowSelectionOnClick
            slots={{ toolbar: CustomExportToolbar }}
            sx={{ ...customized_style.itemDatagrid, height: '78vh' }}
            unstable_cellSelection
            disableRowGrouping
            aggregationModel={aggregationModel}
            onAggregationModelChange={setAggregationModel}
            initialState={{
                columns: {
                    columnVisibilityModel: {
                        id: false,
                    },
                },
            }}
        />
    );
}

export function MonthlyPayroll(props) {
    /** Get props from Payroll.js.  */
    const { monthlyPayroll } = props;

    /** State for datagrid row. */
    const [monthlyPayrollRows, setMonthlyPayrollRows] = useState([]);
    /** State for datagrid columns definition. */
    const [monthlyPayrollCols, setMonthlyPayrollCols] = useState([]);
    /** State for storing the aggregation model. */
    const [aggregationModel, setAggregationModel] = useState({});

    /**
     * Define monthly payroll Datagrid columns.
     * Update monthly payroll Datagrid row.
     */
    useEffect(() => {
        if (monthlyPayroll) {
            const allKeys = monthlyPayroll.flatMap((obj) => {
                return Object.keys(obj).filter((key) => key !== 'id' && key !== 'driver_name');
            });
            const uniqueKeys = [...new Set(allKeys)];
            const new_payrolls = [...uniqueKeys].map((item) => {
                return {
                    field: item,
                    headerName: item.toString(),
                    width: 100,
                    align: 'center',
                    headerAlign: 'center',
                    sortable: false,
                    ...usdPrice,
                };
            });
            const new_payrollsColDef = [
                { field: 'id', headerName: 'ID', width: 30, align: 'center', headerAlign: 'center', hide: true },
                { field: 'driver_name', headerName: 'Driver Name', width: 180, align: 'center', headerAlign: 'center' },
                ...new_payrolls,
            ];
            setMonthlyPayrollCols(new_payrollsColDef);
            setMonthlyPayrollRows(monthlyPayroll);
        }
    }, [monthlyPayroll]);

    /** Effect to update the aggregation model whenever the column definitions change. */
    useEffect(() => {
        setAggregationModel(defaultAggregationModel);
    }, [monthlyPayrollCols]);

    /** Generates the default aggregation model for numerical columns. */
    const defaultAggregationModel = Object.fromEntries(
        monthlyPayrollCols.filter((col) => col.type === 'number').map((col) => [col.field, 'sum'])
    );

    return (
        <DataGridPremium
            rows={monthlyPayrollRows}
            columns={monthlyPayrollCols}
            disableRowSelectionOnClick
            slots={{ toolbar: CustomExportToolbar }}
            sx={{ ...customized_style.itemDatagrid, height: '78vh' }}
            density="compact"
            unstable_cellSelection
            disableRowGrouping
            aggregationModel={aggregationModel}
            onAggregationModelChange={setAggregationModel}
            initialState={{
                columns: {
                    columnVisibilityModel: {
                        id: false,
                    },
                },
            }}
        />
    );
}

export function WeeklyPayroll(props) {
    /** Get props from App.js.  */
    const { userId } = props;
    /** Get props from Payroll.js.  */
    const { weeklyPayroll, setWeeklyPayroll, dateState, setDateState } = props;

    /** Custom API Request function */
    const apiRequest = useApiRequest();

    /** State for managing the snackbar that displays the result of an API request. */
    const [snackbar, setSnackbar] = useState({ open: false, message: '', severity: 'success' });

    /** State for API loading. */
    const [subLoaded, setSubLoaded] = useState(true);

    /** State for searched date. */
    const [dateRange, setDateRange] = useState('');
    /** Weekly payroll Datagrid row. */
    const [weeklyPayrollRows, setWeeklyPayrollRows] = useState([]);

    /**
     * Initialize selected date format.
     * Format : MM/DD/YYYY ~ MM/DD/YYYY
     *  */
    useEffect(() => {
        const formatted_date =
            dayjs(dateState[0].startDate).format('MM/DD/YYYY') + '~' + dayjs(dateState[0].endDate).format('MM/DD/YYYY');
        setDateRange(formatted_date);
    }, []);

    /**
     * Update the weekly payroll rows when the weeklyPayroll state changes.
     * Calculates the total for each driver and formats the data for the DataGrid.
     */
    useEffect(() => {
        if (weeklyPayroll) {
            const driverTotals = [...weeklyPayroll].reduce((acc, curr) => {
                const { driver_name, total } = curr;
                if (!acc[driver_name]) {
                    acc[driver_name] = 0;
                }
                acc[driver_name] += total;
                return acc;
            }, {});

            const filtered = Object.entries(driverTotals).map(([driver_name, total], index) => ({
                id: index + 1,
                driver_name,
                total,
            }));
            setWeeklyPayrollRows(filtered);
        }
    }, [weeklyPayroll]);

    /** Columns configuration for the weekly payroll DataGrid. */
    const weeklyPayrollCol = [
        { field: 'id', headerName: 'ID', width: 50, align: 'center', headerAlign: 'center', hide: true },
        { field: 'driver_name', headerName: 'Driver Name', width: 200, align: 'center', headerAlign: 'center' },
        { field: 'total', headerName: dateRange, width: 200, align: 'center', headerAlign: 'center', ...usdPrice },
    ];

    /** Handle to change the selected date range value. */
    const handleDateChange = (item) => {
        setDateState([item.selection]);
    };

    /**
     * Handle to search for payroll data based on the selected date range.
     * Makes an API request with the selected date range and updates the weekly payroll data accordingly.
     */
    const handleDateSearch = () => {
        const selected_date = dateState.map((item) => {
            return {
                date_from: item.startDate,
                date_to: item.endDate,
            };
        });
        setSubLoaded(false);
        apiRequest('payroll', 'search', userId, selected_date)
            .then((response) => {
                setSubLoaded(true);
                if (response.statusCode === 200) {
                    setSnackbar({ open: true, message: response.body.message, severity: 'success' });
                    setWeeklyPayroll(response.body.weekly_payroll);
                    const formatted_date =
                        dayjs(dateState[0].startDate).format('MM/DD/YYYY') +
                        '~' +
                        dayjs(dateState[0].endDate).format('MM/DD/YYYY');
                    setDateRange(formatted_date);
                } else {
                    setSnackbar({ open: true, message: response.body.message, severity: 'error' });
                }
            })
            .catch((error) => {
                setSnackbar({ open: true, message: 'Something went wrong. Please try again.', severity: 'error' });
            });
    };

    /** Get the content of the detail panel for a row in the DataGrid. */
    const getDetailPanelContent = useCallback(
        ({ row }) => <PayrollDataSub row={row} weeklyPayroll={weeklyPayroll} dateRange={dateRange} />,
        [weeklyPayroll, dateRange]
    );
    /** Returns 'auto' to allow the panel to adjust its height based on the content. */
    const getDetailPanelHeight = useCallback(() => 'auto', []);

    return (
        <>
            <Grid container>
                <Grid item md={12} lg={6} xl={4.5}>
                    <DateRangePicker
                        onChange={handleDateChange}
                        showSelectionPreview={true}
                        moveRangeOnFirstSelection={false}
                        months={1}
                        ranges={dateState}
                        direction="horizontal"
                        rangeColors={['#00657e', '#809da9', '#80b2bf']}
                        style={{
                            fontSize: '11px',
                            width: '100%',
                        }}
                    />
                    <Grid container justifyContent="flex-end">
                        <Button
                            sx={{
                                border: '1px solid #00657e',
                                mr: 2,
                                mb: 2,
                                ':hover': {
                                    backgroundColor: '#003a52',
                                    color: 'white',
                                },
                            }}
                            size="small"
                            onClick={handleDateSearch}
                        >
                            Search
                        </Button>
                    </Grid>
                </Grid>
                <Grid item md={12} lg={6} xl={7.5}>
                    {subLoaded ? (
                        <DataGridPremium
                            rows={weeklyPayrollRows}
                            columns={weeklyPayrollCol}
                            disableRowSelectionOnClick
                            slots={{ toolbar: CustomExportToolbar }}
                            sx={{ ...customized_style.itemDatagrid, height: '78vh' }}
                            unstable_cellSelection
                            disableRowGrouping
                            rowHeight={38}
                            initialState={{
                                columns: {
                                    columnVisibilityModel: {
                                        id: false,
                                    },
                                },
                            }}
                            getDetailPanelHeight={getDetailPanelHeight}
                            getDetailPanelContent={getDetailPanelContent}
                        />
                    ) : (
                        <Box display="flex" justifyContent="center" alignItems="center" sx={{ height: '100%' }}>
                            <CircularProgress />
                        </Box>
                    )}
                </Grid>
            </Grid>
            <CustomSnackbar snackbar={snackbar} setSnackbar={setSnackbar} />
        </>
    );
}
