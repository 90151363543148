import * as React from 'react';
import { useEffect } from 'react';

/** Material UI Packages. */
import { Box, useMediaQuery, Typography, Button } from '@mui/material';

/** Auth0 authenticator Packages. */
import { useAuth0 } from '@auth0/auth0-react';

/** Internal Components. */
import inactive from '../statics/images/inactive.png';
import Copyright from '../components/functions/CopyRight';

export default function InactivePage() {
    /** Destructuring values from useAuth0 hook. */
    const { logout } = useAuth0();

    /** Detecting window size for responsive web. */
    const isMediumScreen = useMediaQuery('(max-width: 1493px)');

    /** Set document title. */
    useEffect(() => {
        document.title = 'Inactive | Insight Logistics ';
    }, []);

    /** When logout button click, redirect to endpoint : /login.  */
    const logoutWithRedirect = () => {
        logout({ returnTo: 'http://localhost:3001' });
    };

    return (
        <>
            <Box
                sx={{
                    backgroundImage: `url(${inactive})`,
                    backgroundRepeat: 'no-repeat',
                    backgroundColor: (t) => (t.palette.mode === 'light' ? t.palette.grey[50] : t.palette.grey[900]),
                    backgroundSize: 'cover',
                    backgroundPosition: 'center',
                    position: 'relative',
                    width: '100%',
                    height: '100vh',
                }}
            >
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        justifyContent: 'center',
                        position: 'absolute',
                        top: '50%',
                        left: '50%',
                        transform: 'translate(-50%, -50%)',
                        zIndex: 2,
                        width: isMediumScreen ? '70%' : '50%',
                        height: '50vh',
                        m: '0 auto',
                        borderRadius: 5,
                        bgcolor: '#ffffff',
                        opacity: 0.85,
                        p: isMediumScreen ? 5 : 2,
                    }}
                >
                    <Typography
                        sx={{
                            fontSize: '2rem',
                            fontWeight: 'bold',
                            color: '#003a52',
                            mb: 3,
                        }}
                    >
                        Unauthorized:(
                    </Typography>
                    <Typography sx={{ fontSize: '1.3rem' }}>
                        Access to this website is restricted due to insufficient permissions.
                    </Typography>
                    <Typography sx={{ fontSize: '1.3rem', display: 'inline' }}>
                        <span>For more information, please contact us at </span>
                        <span style={{ textDecoration: 'underline' }}>it@insightshippinginc.com</span>
                    </Typography>
                    <Button
                        onClick={logoutWithRedirect}
                        sx={{
                            width: isMediumScreen ? '30%' : '20%',
                            height: '5vh',
                            bgcolor: '#00647e',
                            color: 'white',
                            fontWeight: 'bold',
                            borderRadius: 5,
                            mt: 7,
                            ':hover': {
                                backgroundColor: '#003a52',
                            },
                        }}
                    >
                        Go Home
                    </Button>
                </Box>
                <Copyright />
            </Box>
        </>
    );
}
