import * as React from 'react';

/** Date format for datagrid. */
const date_format = { year: '2-digit', month: '2-digit', day: '2-digit' };

/** Define History Datagrid Fields. */
export const allHistoryCol = [
    { field: 'id', headerName: 'ID', width: 50, align: 'center', headerAlign: 'center' },
    {
        field: 'dispatch_date',
        headerName: 'Date',
        type: 'date',
        width: 75,
        align: 'center',
        headerAlign: 'center',
        valueFormatter: (params) =>
            params.value ? new Date(params.value).toLocaleDateString('en-US', date_format) : null,
    },
    {
        field: 'team',
        headerName: 'Team',
        width: 70,
        align: 'center',
        headerAlign: 'center',
    },
    {
        field: 'client',
        headerName: 'Client',
        width: 150,
        align: 'center',
        headerAlign: 'center',
    },
    {
        field: 'mbl',
        headerName: 'Booking# / MBL#',
        width: 170,
        align: 'center',
        headerAlign: 'center',
    },
    {
        field: 'cntr',
        headerName: 'CNTR#',
        width: 115,
        align: 'center',
        headerAlign: 'center',
    },
    {
        field: 'size',
        headerName: 'Size',
        width: 65,
        align: 'center',
        headerAlign: 'center',
    },
    {
        field: 'terminal',
        headerName: 'Terminal',
        width: 120,
        align: 'center',
        headerAlign: 'center',
    },
    {
        field: 'vessel',
        headerName: 'Vessel',
        width: 170,
        align: 'center',
        headerAlign: 'center',
    },
    {
        field: 'eta',
        headerName: 'ETA',
        type: 'date',
        width: 90,
        align: 'center',
        headerAlign: 'center',
        valueFormatter: (params) =>
            params.value ? new Date(params.value).toLocaleDateString('en-US', date_format) : null,
    },
    {
        field: 'etb',
        headerName: 'ETB',
        type: 'date',
        width: 90,
        align: 'center',
        headerAlign: 'center',
        valueFormatter: (params) =>
            params.value ? new Date(params.value).toLocaleDateString('en-US', date_format) : null,
    },
    {
        field: 'dem',
        headerName: 'DEM.LFD',
        type: 'date',
        width: 90,
        align: 'center',
        headerAlign: 'center',
        valueFormatter: (params) =>
            params.value ? new Date(params.value).toLocaleDateString('en-US', date_format) : null,
    },
    {
        field: 'pdiem',
        headerName: 'P.DIEM.LFD',
        type: 'date',
        width: 90,
        align: 'center',
        headerAlign: 'center',
        valueFormatter: (params) =>
            params.value ? new Date(params.value).toLocaleDateString('en-US', date_format) : null,
    },
    {
        field: 'appt',
        headerName: 'APPT',
        type: 'date',
        width: 120,
        align: 'center',
        headerAlign: 'center',
        valueFormatter: (params) =>
            params.value
                ? `${new Date(params.value).toLocaleDateString('en-US', {
                      month: '2-digit',
                      day: '2-digit',
                      year: '2-digit',
                  })}, ${new Date(params.value).toLocaleTimeString('en-US', {
                      hour: '2-digit',
                      minute: '2-digit',
                      hour12: false,
                  })}`
                : null,
    },
    {
        field: 'empty_date',
        headerName: 'Empty Date',
        type: 'date',
        width: 90,
        align: 'center',
        headerAlign: 'center',
        valueFormatter: (params) =>
            params.value ? new Date(params.value).toLocaleDateString('en-US', date_format) : null,
    },
    {
        field: 'aim_chassis',
        headerName: 'AIM Chassis',
        width: 120,
        align: 'center',
        headerAlign: 'center',
    },
    {
        field: 'obl',
        headerName: 'OBL',
        width: 85,
        type: 'boolean',
        align: 'center',
        headerAlign: 'center',
    },
    {
        field: 'customs',
        headerName: 'Customs',
        width: 85,
        type: 'boolean',
        align: 'center',
        headerAlign: 'center',
    },
    {
        field: 'pierpass',
        headerName: 'PierPass',
        width: 85,
        type: 'boolean',
        align: 'center',
        headerAlign: 'center',
    },
    {
        field: 'remark',
        headerName: 'Remark',
        width: 150,
        align: 'center',
        headerAlign: 'center',
    },
    {
        field: 'status',
        headerName: 'Close',
        width: 80,
        type: 'boolean',
        align: 'center',
        headerAlign: 'center',
        editable: true,
    },
];

/** Columns configuration for the movement detail DataGrid. */
export const movement_col = [
    { field: 'id', headerName: 'ID', align: 'center', headerAlign: 'center', width: 50 },
    {
        field: 'deliveryDate',
        headerName: 'Move Date',
        align: 'center',
        headerAlign: 'center',
        width: 150,
        valueFormatter: (params) =>
            params.value
                ? `${new Date(params.value).toLocaleDateString('en-US', {
                      month: '2-digit',
                      day: '2-digit',
                      year: '2-digit',
                  })}, ${new Date(params.value).toLocaleTimeString('en-US', {
                      hour: '2-digit',
                      minute: '2-digit',
                      hour12: false,
                  })}`
                : null,
    },
    { field: 'pickup', headerName: 'Pick Up', align: 'center', headerAlign: 'center', width: 200 },
    {
        field: 'delivery',
        headerName: 'Delivery',
        align: 'center',
        headerAlign: 'center',
        width: 200,
    },
    {
        field: 'driver',
        headerName: 'Driver',
        align: 'center',
        headerAlign: 'center',
        width: 200,
    },
    { field: 'type', headerName: 'Type', align: 'center', headerAlign: 'center', width: 150 },
    { field: 'method', headerName: 'Method', align: 'center', headerAlign: 'center', width: 150 },
    { field: 'chassis_name', headerName: 'Chassis', align: 'center', headerAlign: 'center', width: 150 },
    { field: 'movementstatus', headerName: 'Status', align: 'center', headerAlign: 'center', width: 150 },
];
