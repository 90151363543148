import React, { useState, useEffect, useRef } from 'react';

/** Material UI Packages. */
import { Autocomplete, TextField, Popper } from '@mui/material';
import { styled } from '@mui/material/styles';

const StyledPopper = styled(Popper)({
    '& .MuiAutocomplete-listbox': {
        fontSize: '12px',
        width: '100%',
        maxHeight: '200px',
    },
});

/** Autocomplete Component : Value holds the ID, but the label displays the CONTENT value. */
const CustomAutocompleteCell = ({ params, options, value, shouldFocus }) => {
    const inputRef = useRef(null);

    const handleChange = (event, newValue) => {
        params.api.setEditCellValue({
            id: params.id,
            field: params.field,
            value: newValue ? newValue.id : null,
        });
    };

    useEffect(() => {
        if (shouldFocus && inputRef.current) {
            inputRef.current.focus();
        }
    }, [shouldFocus]);

    return (
        <>
            <Autocomplete
                value={options.find((option) => option.id === value) || null}
                options={options}
                getOptionLabel={(option) => option.name}
                isOptionEqualToValue={(option, value) => option.id === value.id}
                onChange={handleChange}
                disableClearable
                renderInput={(inputParams) => (
                    <TextField
                        {...inputParams}
                        inputRef={inputRef}
                        variant="standard"
                        sx={{
                            px: 0.3,
                            '& .MuiInputBase-root': {
                                border: 'none',
                            },
                        }}
                    />
                )}
                disablePortal={false}
                fullWidth
                slotProps={{
                    popper: {
                        component: StyledPopper,
                    },
                }}
                sx={{
                    height: '100%',
                    '& .MuiInputBase-input': {
                        fontSize: '12px',
                    },
                    '& .MuiInputBase-root': {
                        p: 0,
                        height: '100%',
                    },
                    '& .MuiFormControl-root': {
                        height: '100%',
                    },
                }}
            />
        </>
    );
};

export { CustomAutocompleteCell };
