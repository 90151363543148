import * as React from 'react';
import { useState } from 'react';

/** Material UI Packages. */
import { Fab } from '@mui/material';

/** Material UI Icons. */
import RvHookupIcon from '@mui/icons-material/RvHookup';

/** Internal Components. */
import ChassisDialog from './ChassisDialog';
import CustomSnackbar from '../../../CustomSnackbar';

export default function ChassisStatus(props) {
    /** Get props from App.js.  */
    const { userId } = props;

    /** State for managing the snackbar that displays the result of an API request. */
    const [snackbar, setSnackbar] = useState({ open: false, message: '', severity: 'success' });

    /** Dialog open state using Fab component. */
    const [chassisOpen, setChassisOpen] = useState(false);

    /** Handler to open dialog. */
    const handleFabClick = () => {
        setChassisOpen(true);
    };

    return (
        <>
            <Fab
                size="medium"
                color="primary"
                aria-label="add"
                sx={{ position: 'absolute', bottom: 75, right: 16 }}
                onClick={handleFabClick}
            >
                <RvHookupIcon />
            </Fab>
            <ChassisDialog
                userId={userId}
                chassisOpen={chassisOpen}
                setChassisOpen={setChassisOpen}
                setSnackbar={setSnackbar}
            />
            <CustomSnackbar snackbar={snackbar} setSnackbar={setSnackbar} />
        </>
    );
}
