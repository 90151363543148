import * as React from 'react';
import { useEffect, useState } from 'react';

/** Router Packages. */
import { useNavigate } from 'react-router-dom';

/** Auth0 authenticator Packages. */
import { useAuth0 } from '@auth0/auth0-react';

/** Internal Components */
import Main from './Main_ver2.0.0';

import dayjs from 'dayjs';

export default function RenderComponent(props) {
    /** Get props from App.js.  */
    const { userId } = props;
    /** Get props from App.js.  */
    const { dashboard, deliveryorder, schedule, manifest, dataentry, rate, history, payroll, myprofile, logs } = props;

    /** Get authentication status and user information. */
    const { isAuthenticated, logout, user } = useAuth0();

    /** Navigate to different routes. */
    const navigate = useNavigate();

    /** User roles extracted from Auth0 */
    const userRole = user?.['https://drayage.insightshipping.io/roles'] ?? [0];

    /** Access Token expiry checker. */
    const [isTokenExpired, setIsTokenExpired] = useState(false);

    /**
     * Check token expiry and set token expiry checker to True.
     * @param {Object} isTokenExpired - Token expiry state object.
     */
    useEffect(() => {
        if (!isAuthenticated) {
            return;
        }
        const checkTokenExpiry = () => {
            const tokenExpiry = sessionStorage.getItem('exp');
            if (tokenExpiry && new Date(tokenExpiry) < dayjs()) {
                setIsTokenExpired(true);
            }
        };
        const interval = setInterval(() => {
            checkTokenExpiry();
        }, 60000);
        return () => clearInterval(interval);
    }, []);

    /** Hook to logout once token is expired. */
    useEffect(() => {
        if (isTokenExpired) {
            logout({
                returnTo: window.location.origin + '/login',
            });
        }
    }, [isTokenExpired]);

    /** Restrict access to unauthorized users */
    const restrictAccess = (userRole) => {
        if (!['Dispatch-Staff', 'Dispatch-Manager', 'Administrator'].some((role) => userRole.includes(role))) {
            return navigate('/inactive');
        }
    };

    /** Restrict access except for manager. */
    const restrictManager = (userRole) => {
        restrictAccess(userRole);
        if (userRole.includes('Dispatch-Staff')) {
            return navigate('/dashboard');
        }
    };

    /** Restrict access except for administrator. */
    const restrictAdmin = (userRole) => {
        restrictAccess(userRole);
        if (!userRole.includes('Administrator')) {
            return navigate('/dashboard');
        }
    };

    /** Effect to handle navigation based on props and userRole. */
    useEffect(() => {
        const handleNavigation = () => {
            switch (true) {
                case dashboard:
                case deliveryorder:
                case schedule:
                case manifest:
                case history:
                    restrictAccess(userRole);
                    break;
                case dataentry:
                case rate:
                case payroll:
                    restrictManager(userRole);
                    break;
                case logs:
                    restrictAdmin(userRole);
                    break;
                case myprofile:
                    restrictAccess(userRole);
                    break;
                default:
                    break;
            }
        };
        handleNavigation();
    }, [
        userRole,
        dashboard,
        deliveryorder,
        schedule,
        manifest,
        history,
        dataentry,
        rate,
        payroll,
        logs,
        myprofile,
        isAuthenticated,
    ]);

    return (
        <Main
            userId={userId}
            userRole={userRole}
            dashboard={dashboard}
            deliveryorder={deliveryorder}
            schedule={schedule}
            manifest={manifest}
            history={history}
            dataentry={dataentry}
            rate={rate}
            payroll={payroll}
            logs={logs}
            myprofile={myprofile}
        />
    );
}
