import * as React from 'react';
import { useMemo, useState } from 'react';

/** Material UI Packages. */
import MuiDrawer from '@mui/material/Drawer';
import MuiAppBar from '@mui/material/AppBar';
import {
    styled,
    createTheme,
    ThemeProvider,
    Box,
    Toolbar,
    List,
    Typography,
    CssBaseline,
    Tooltip,
    Link,
} from '@mui/material';

/** Material UI Icons. */
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import InsertEmoticonIcon from '@mui/icons-material/InsertEmoticon';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import LogoutIcon from '@mui/icons-material/Logout';

/** Auth0 authenticator Packages. */
import { useAuth0 } from '@auth0/auth0-react';

/** Client-side routing Library.  */
import { Link as RouterLink } from 'react-router-dom';

/** Internal Components. */
import insight_logo_horizontal from '../../statics/images/insight_logo_horizontal.png';
import MenuListItems from './SidebarList';
import Dashboard from '../functions/user/Dashboard/Dashboard';
import DeliveryOrder from '../functions/user/DeliveryOrder/DeliveryOrder';
import Schedule from '../functions/user/Schedule/Schedule';
import Manifest from '../functions/user/Manifest/Manifest';
import History from '../functions/user/History/History';
import Dataentry from '../functions/manager/DataEntry/DataEntry';
import Rate from '../functions/manager/Rate/Rate';
import Payroll from '../functions/manager/Payroll/Payroll';
import Logs from '../functions/admin/Logs/Logs';
import MyProfile from '../functions/user/Myprofile/MyProfile';

/** Set up general theme. */
const theme = createTheme({
    palette: {
        primary: {
            light: '#80b2bf',
            main: '#00647e',
            dark: '#003a52',
        },
    },
    typography: {
        fontFamily: 'NotoSans',
        fontWeightRegular: 400,
        fontWeightBold: 600,
        fontSize: 12,
    },
    textfield: {
        fontSize: 12,
    },
    components: {
        MuiSelect: {
            styleOverrides: {
                select: {
                    fontSize: '14px',
                },
                menuItem: {
                    fontSize: '14px',
                },
            },
        },
    },
});

/** Always displayed at the bottom for Copyright. */
function Copyright(props) {
    return (
        <Typography variant="body2" color="text.secondary" align="center" {...props}>
            {'Copyright © '}
            <Link color="inherit" href="https://www.insightshippinginc.com/">
                Insight Shipping, Inc.
            </Link>{' '}
            {new Date().getFullYear()}
            {'.'}
        </Typography>
    );
}

// Width of the drawer
const drawerWidth = 240;

const AppBar = styled(MuiAppBar, {
    shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
    zIndex: theme.zIndex.drawer + 1,
    // Transition styles for opening and closing the drawer
    transition: theme.transitions.create(['width', 'margin'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
    }),
    ...(open && {
        // Styles applied when the drawer is open
        marginLeft: drawerWidth,
        width: `calc(100% - ${drawerWidth}px)`,
        transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
    }),
}));

const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(({ theme, open }) => ({
    '& .MuiDrawer-paper': {
        position: 'relative',
        whiteSpace: 'nowrap',
        width: drawerWidth,
        // Transition styles for opening the drawer
        transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
        boxSizing: 'border-box',
        border: 'none',
        ...(!open && {
            // Styles applied when the drawer is closed
            overflowX: 'hidden',
            transition: theme.transitions.create('width', {
                easing: theme.transitions.easing.sharp,
                duration: theme.transitions.duration.leavingScreen,
            }),
            width: theme.spacing(7),
            [theme.breakpoints.up('md')]: {
                width: theme.spacing(11),
            },
        }),
    },
}));

export default function Main(props) {
    /** Get props from App.js.  */
    const {
        dashboard,
        deliveryorder,
        schedule,
        manifest,
        dataentry,
        rate,
        history,
        payroll,
        myprofile,
        logs,
        userRole,
        userId,
    } = props;
    /** Destructuring useAuth0 to get logout method. */
    const { logout } = useAuth0();

    /** Controlling menu bar visibility. */
    const [menubarOpen, setMenubarOpen] = useState(true);

    /** Handle to open side menubar. */
    const toggleDrawer = () => {
        setMenubarOpen(!menubarOpen);
    };

    /** When logout button click, redirect to endpoint : /login.  */
    const logoutWithRedirect = () => {
        logout({
            returnTo: window.location.origin + '/login',
        });
    };

    /** Use a switch statement to determine which component to render based on the boolean props. */
    const PageComponent = (props) => {
        const {
            dashboard,
            deliveryorder,
            schedule,
            manifest,
            dataentry,
            rate,
            history,
            payroll,
            myprofile,
            logs,
            userId,
        } = props;

        switch (true) {
            case dashboard:
                return <Dashboard userId={userId} />;
            case deliveryorder:
                return <DeliveryOrder userId={userId} />;
            case schedule:
                return <Schedule userId={userId} />;
            case manifest:
                return <Manifest userId={userId} />;
            case history:
                return <History userId={userId} />;
            case myprofile:
                return <MyProfile userId={userId} />;
            case dataentry:
                return <Dataentry userId={userId} />;
            case rate:
                return <Rate userId={userId} />;
            case payroll:
                return <Payroll userId={userId} />;
            case logs:
                return <Logs userId={userId} />;
        }
    };

    /** Hook to prevent re-rendering when implementing toggleDrawer. */
    const PageComponentMemo = useMemo(
        () => PageComponent,
        [dashboard, deliveryorder, schedule, manifest, dataentry, rate, history, payroll, myprofile, logs, userId]
    );

    return (
        <ThemeProvider theme={theme}>
            <Box sx={{ display: 'flex', bgcolor: '#80b2bf' }}>
                <CssBaseline />
                <AppBar position="absolute" open={menubarOpen} sx={{ boxShadow: 'none' }}>
                    <Toolbar sx={{ bgcolor: 'white', color: '#00657e', height: 60 }}>
                        <IconButton
                            edge="start"
                            color="inherit"
                            onClick={toggleDrawer}
                            sx={{
                                marginLeft: '5px',
                                marginRight: '20px',
                                ...(menubarOpen && { display: 'none' }),
                            }}
                        >
                            <MenuIcon />
                        </IconButton>
                        <Typography
                            component="h1"
                            variant="h5"
                            color="inherit"
                            noWrap
                            sx={{ flexGrow: 1 }}
                        ></Typography>
                        <Tooltip title="My Profile">
                            <IconButton component={RouterLink} to="/myprofile" color="inherit">
                                <InsertEmoticonIcon />
                            </IconButton>
                        </Tooltip>
                        <Tooltip title="LogOut">
                            <IconButton component="a" color="inherit" onClick={logoutWithRedirect}>
                                <LogoutIcon />
                            </IconButton>
                        </Tooltip>
                    </Toolbar>
                </AppBar>
                <Drawer variant="permanent" open={menubarOpen}>
                    <Toolbar
                        sx={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            px: [1],
                            color: '#00657e',
                        }}
                    >
                        <RouterLink to="/dashboard" style={{ cursor: 'pointer' }}>
                            <img
                                alt="logo"
                                src={insight_logo_horizontal}
                                style={{
                                    width: '129px',
                                    height: '58px',
                                    marginTop: 6,
                                    marginRight: 12,
                                    marginLeft: 12,
                                }}
                            ></img>
                        </RouterLink>
                        <IconButton color="inherit" onClick={toggleDrawer} sx={{ mt: 2 }}>
                            <ChevronLeftIcon />
                        </IconButton>
                    </Toolbar>
                    <List component="nav" sx={{ m: 2 }}>
                        <MenuListItems userRole={userRole} />
                    </List>
                </Drawer>
                <Box
                    component="div"
                    className="main"
                    sx={{
                        backgroundColor: (theme) =>
                            theme.palette.mode === 'light' ? theme.palette.grey[100] : theme.palette.grey[900],
                        flexGrow: 1,
                        height: '100vh',
                        overflow: 'auto',
                    }}
                >
                    <Toolbar />
                    <PageComponentMemo
                        userRole={userRole}
                        dashboard={dashboard}
                        deliveryorder={deliveryorder}
                        schedule={schedule}
                        manifest={manifest}
                        dataentry={dataentry}
                        rate={rate}
                        history={history}
                        payroll={payroll}
                        myprofile={myprofile}
                        logs={logs}
                        userId={userId}
                    />
                    {/* <Copyright /> */}
                </Box>
            </Box>
        </ThemeProvider>
    );
}
