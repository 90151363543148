import * as React from 'react';

/** Material UI Packages. */
import { Box, Grid, IconButton, Typography } from '@mui/material';

/** Material UI Icons. */
import AssignmentTurnedInOutlinedIcon from '@mui/icons-material/AssignmentTurnedInOutlined';
import DirectionsBoatFilledRoundedIcon from '@mui/icons-material/DirectionsBoatFilledRounded';

/** Internal Components. */
import { dashboard_style } from '../../../theme/Styles';
import useApiRequest from '../../useApiRequest';

/**
 * CustomCard component for rendering a styled card with an icon, count, and title.
 *
 * @param {*} param0 - Destructured props
 * @returns -JSX element representing a styled card.
 */
const CustomCard = ({
    icon,
    bgColor,
    count,
    title,
    children,
    btnColor,
    letterColor,
    handle_method,
    parameter,
    setSnackbar,
}) => {
    /** Custom API Request function */
    const apiRequest = useApiRequest();

    const handleChangeCard = (parameter) => {
        apiRequest('dashboard', parameter, 1, [])
            .then((response) => {
                handle_method(response.body.data);
                setSnackbar({ open: true, message: response.body.message, severity: 'success' });
            })
            .catch((error) => {
                setSnackbar({ open: true, message: 'Oops, something went wrong :(', severity: 'error' });
            });
    };

    return (
        <Box sx={{ width: '100%', height: 80, borderRadius: '10px', background: bgColor }}>
            <Grid container>
                <Grid item lg={1.5}>
                    <IconButton
                        onClick={() => handleChangeCard(parameter)}
                        sx={{ bgcolor: btnColor, opacity: 0.8, my: '15px', ml: '15px' }}
                    >
                        {icon}
                    </IconButton>
                </Grid>
                <Grid item lg={8}>
                    <Typography sx={dashboard_style.itemTypographyCount}>{count}</Typography>
                    <Typography
                        sx={{ fontSize: '0.8rem', fontWeight: 'bold', ml: 2, color: letterColor, lineHeight: 1 }}
                    >
                        {title}
                    </Typography>
                </Grid>
                <Grid item lg={2.5}>
                    <Box
                        name="ship"
                        sx={{
                            position: 'relative',
                            top: '52%',
                            left: '73%',
                            transform: 'translate(-50%,-50%)',
                            width: '132px',
                            height: '81px',
                            bgcolor: 'transparent',
                        }}
                    >
                        {children}
                    </Box>
                </Grid>
            </Grid>
        </Box>
    );
};

/**
 * DashboardDemCard component representing a specific instance of the CustomCard for Demurrage.
 *
 * @param {Object} props
 * @returns JSX element representing the Demurrage card.
 */

export function DashboardDemCard(props) {
    /** Get props from Dashboard.js.  */
    const { demCount, setDemData, setSnackbar } = props;

    const handle_method = (data) => {
        setDemData(data);
    };

    return (
        <CustomCard
            icon={<AssignmentTurnedInOutlinedIcon fontSize="large" sx={{ color: 'white' }} />}
            bgColor="linear-gradient(to right bottom, #caf0f8, #0077b6)"
            count={demCount}
            title="Containers Approaching Demurrage Date"
            btnColor="#0077b6"
            letterColor="#0a2472"
            handle_method={handle_method}
            parameter={'demurrage'}
            setSnackbar={setSnackbar}
        >
            <Box
                sx={{
                    position: 'absolute',
                    width: '60px',
                    height: '60px',
                    borderRadius: '50%',
                    bgcolor: '#02476b',
                    opacity: '0.5',
                    left: '30px',
                }}
            ></Box>
            <Box
                sx={{
                    position: 'absolute',
                    width: '40px',
                    height: '40px',
                    borderRadius: '50%',
                    bgcolor: '#02476b',
                    opacity: '0.5',
                }}
            ></Box>
        </CustomCard>
    );
}

/**
 * DashboardPdeimCard component representing a specific instance of the CustomCard for Per Diem.
 *
 * @param {Object} props
 * @returns JSX element representing the Per Diem card.
 */
export function DashboardPdeimCard(props) {
    /** Get props from Dashboard.js.  */
    const { pdiemCount, setPerdiemData, setSnackbar } = props;

    const handle_method = (data) => {
        setPerdiemData(data);
    };

    return (
        <CustomCard
            icon={<DirectionsBoatFilledRoundedIcon fontSize="large" sx={{ color: 'white' }} />}
            bgColor="linear-gradient(to right bottom, #9d4edd, #e0aaff)"
            count={pdiemCount}
            title="Containers Approaching Per Diem Date"
            btnColor="#5a189a"
            letterColor="#5a189a"
            handle_method={handle_method}
            parameter={'perdiem'}
            setSnackbar={setSnackbar}
        >
            <Box
                sx={{
                    position: 'absolute',
                    width: '40px',
                    height: '40px',
                    bgcolor: '#3c096c',
                    borderRadius: '50%',
                    opacity: '0.5',
                    left: '50px',
                }}
            ></Box>
            <Box
                sx={{
                    position: 'absolute',
                    width: '60px',
                    height: '60px',
                    borderRadius: '50%',
                    bgcolor: '#3c096c',
                    opacity: '0.5',
                }}
            ></Box>
        </CustomCard>
    );
}
