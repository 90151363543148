import * as React from 'react';
import { useRef, useLayoutEffect, useEffect } from 'react';

/** Material UI Packages. */
import { TextField, MenuItem, Select, Autocomplete, Popper } from '@mui/material';
import { useGridApiContext } from '@mui/x-data-grid-premium';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DateTimeField } from '@mui/x-date-pickers/DateTimeField';
import { styled } from '@mui/material/styles';

/** Date Format Library. */
import dayjs from 'dayjs';

const StyledPopper = styled(Popper)({
    '& .MuiAutocomplete-listbox': {
        fontSize: '12px',
        width: '100%',
        maxHeight: '200px',
    },
});

/** Custom component for editing date-time cells in the DataGrid. */
export function CustomEditComponent(props) {
    const { id, value, field, hasFocus } = props;
    /** Access the DataGrid API context. */
    const apiRef = useGridApiContext();
    const ref = useRef();

    /** Focus the input field when the cell is focused. */
    useLayoutEffect(() => {
        if (hasFocus) {
            ref.current.focus();
        }
    }, [hasFocus]);

    /** Handle changes to the date-time picker value. */
    const handleValueChange = (event) => {
        const newValue = event;
        apiRef.current.setEditCellValue({ id, field, value: newValue });
    };
    return (
        <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DateTimePicker
                ref={ref}
                //Temporary comment out.
                // minDate={dayjs().subtract(3, 'day')}
                slotProps={{
                    textField: {
                        size: '0',
                        required: true,
                        variant: 'standard',
                        InputProps: {
                            disableUnderline: true,
                            style: { fontSize: '14px' },
                        },
                        InputLabelProps: {
                            style: { fontSize: '14px' },
                        },
                    },
                }}
                value={dayjs(value)}
                onChange={handleValueChange}
            />
        </LocalizationProvider>
    );
}

/** Custom component for editing movement status cells in the DataGrid. */
export const CustomStatusEditComponent = ({ id, value, api, field }) => {
    /** Get the entire row data. */
    const row = api.getRow(id);
    /** Extract the driver ID from the row data. */
    const driverId = row.driver_id;

    /** Handle changes to the status select field. */
    const handleChange = (event) => {
        api.setEditCellValue({ id, field, value: event.target.value });
    };

    /** Define the available status options with a conditional disabling for 'Completed' status. */
    const statusOptions = [
        { value: 'In Progress', label: 'In Progress' },
        { value: 'Completed', label: 'Completed', disabled: driverId === null },
        { value: 'Failed', label: 'Failed' },
        { value: 'Canceled', label: 'Canceled' },
        { value: 'Street Turned', label: 'Street Turned' },
    ];

    return (
        <TextField select value={value || ''} onChange={handleChange} fullWidth>
            {statusOptions.map((option) => (
                <MenuItem key={option.value} value={option.value} disabled={option.disabled} sx={{ fontSize: '14px' }}>
                    {option.label}
                </MenuItem>
            ))}
        </TextField>
    );
};

export function CustomDateTime(props) {
    const { id, value, field, hasFocus } = props;
    /** Access the DataGrid API context. */
    const apiRef = useGridApiContext();
    const ref = useRef();

    /** Focus the input field when the cell is focused. */
    useLayoutEffect(() => {
        if (hasFocus) {
            ref.current.focus();
        }
    }, [hasFocus]);

    /** Handle changes to the date-time picker value. */
    const handleValueChange = (event) => {
        const newValue = event;
        apiRef.current.setEditCellValue({ id, field, value: newValue });
    };
    return (
        <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DateTimeField
                ref={ref}
                format="L HH:mm"
                value={dayjs(value)}
                onChange={handleValueChange}
                sx={{ fontSize: '12px' }}
            />
        </LocalizationProvider>
    );
}

/** Autocomplete Component : Value holds the ID, but the label displays the CONTENT value. */
export const CustomAutocompleteCell = ({ params, options, value, shouldFocus }) => {
    const inputRef = useRef(null);
    const handleChange = (event, newValue) => {
        params.api.setEditCellValue({
            id: params.id,
            field: params.field,
            value: newValue ? newValue.id : null,
        });
    };

    useEffect(() => {
        if (shouldFocus && inputRef.current) {
            inputRef.current.focus();
        }
    }, [shouldFocus]);

    return (
        <>
            <Autocomplete
                value={options.find((option) => option.id === value) || null}
                options={options}
                getOptionLabel={(option) => option.name}
                isOptionEqualToValue={(option, value) => option.id === value.id}
                onChange={handleChange}
                disableClearable
                renderInput={(inputParams) => (
                    <TextField
                        {...inputParams}
                        inputRef={inputRef}
                        variant="standard"
                        sx={{
                            px: 0.3,
                            '& .MuiInputBase-root': {
                                border: 'none',
                            },
                        }}
                    />
                )}
                disablePortal={false}
                fullWidth
                slotProps={{
                    popper: {
                        component: StyledPopper,
                    },
                }}
                sx={{
                    height: '100%',
                    '& .MuiInputBase-input': {
                        fontSize: '12px',
                    },
                    '& .MuiInputBase-root': {
                        p: 0,
                        height: '100%',
                    },
                    '& .MuiFormControl-root': {
                        height: '100%',
                    },
                }}
            />
        </>
    );
};

/** Autocomplete Component : Value holds the ID, but the label displays the CONTENT value. */
export const CustomAutocompleteCell_string = ({ params, options, value, shouldFocus }) => {
    const inputRef = useRef(null);
    const handleChange = (event, newValue) => {
        params.api.setEditCellValue({
            id: params.id,
            field: params.field,
            value: newValue,
        });
    };

    useEffect(() => {
        if (shouldFocus && inputRef.current) {
            inputRef.current.focus();
        }
    }, [shouldFocus]);

    return (
        <>
            <Autocomplete
                value={value}
                options={options}
                onChange={handleChange}
                disableClearable
                renderInput={(inputParams) => (
                    <TextField
                        {...inputParams}
                        inputRef={inputRef}
                        variant="standard"
                        sx={{
                            px: 0.3,
                            '& .MuiInputBase-root': {
                                border: 'none',
                            },
                        }}
                    />
                )}
                disablePortal={false}
                fullWidth
                slotProps={{
                    popper: {
                        component: StyledPopper,
                    },
                }}
                sx={{
                    height: '100%',
                    '& .MuiInputBase-input': {
                        fontSize: '12px',
                    },
                    '& .MuiInputBase-root': {
                        p: 0,
                        height: '100%',
                    },
                    '& .MuiFormControl-root': {
                        height: '100%',
                    },
                }}
            />
        </>
    );
};
