import * as React from 'react';

/** Material UI Packages. */
import { GridActionsCellItem } from '@mui/x-data-grid-premium';

/** Material UI Icons. */
import DeleteIcon from '@mui/icons-material/DeleteOutlined';

/** Internal Components. */
import { CustomAutocompleteCell } from './CustomAutoComplete';

/** Date format for datagrid. */
const date_format = { year: '2-digit', month: '2-digit', day: '2-digit' };

const currencyFormatter = (value) => {
    if (value == null) return '';
    return new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD',
    }).format(value);
};

/** Define movements datagrid Fields. */
export const movements_col = (active, unassigned) => {
    let columns = [
        { field: 'id', headerName: 'ID', flex: 0.3, align: 'center', headerAlign: 'center' },
        {
            field: 'move_date',
            headerName: 'Move Date',
            type: 'date',
            flex: 0.5,
            align: 'center',
            headerAlign: 'center',
            valueFormatter: (params) =>
                params.value ? new Date(params.value).toLocaleDateString('en-US', date_format) : null,
        },
        { field: 'cntr', headerName: 'Container', flex: 0.6, align: 'center', headerAlign: 'center' },
        { field: 'size', headerName: 'Size', flex: 0.3, align: 'center', headerAlign: 'center' },
        { field: 'pickup', headerName: 'Pick Up', flex: 0.9, align: 'center', headerAlign: 'center' },
        { field: 'delivery', headerName: 'Delivery', flex: 0.9, align: 'center', headerAlign: 'center' },
        { field: 'driver', headerName: 'Driver', flex: 0.8, align: 'center', headerAlign: 'center' },
        { field: 'type', headerName: 'Type', flex: 0.4, align: 'center', headerAlign: 'center' },
        { field: 'method', headerName: 'Method', flex: 0.4, align: 'center', headerAlign: 'center' },
        { field: 'chassis', headerName: 'Chassis', flex: 0.7, align: 'center', headerAlign: 'center' },
        { field: 'status', headerName: 'Status', flex: 0.5, align: 'center', headerAlign: 'center' },
        {
            field: 'base_rate',
            headerName: 'Base',
            flex: 0.4,
            align: 'center',
            headerAlign: 'center',
            valueFormatter: (params) => currencyFormatter(params.value),
        },
        {
            field: 'addcharge_rate',
            headerName: 'Additional',
            flex: 0.4,
            align: 'center',
            headerAlign: 'center',
            valueFormatter: (params) => currencyFormatter(params.value),
        },
        {
            field: 'total',
            headerName: 'Total',
            flex: 0.4,
            align: 'center',
            headerAlign: 'center',
            valueFormatter: (params) => currencyFormatter(params.value),
        },
        {
            field: 'payment_status',
            headerName: 'Confirmed',
            flex: 0.5,
            type: 'boolean',
            align: 'center',
            headerAlign: 'center',
            editable: true,
            headerClassName: active ? '' : 'editable-cell',
            editable: !active,
        },
    ];
    if (active) {
        columns = columns.filter(
            (col) => !['base_rate', 'addcharge_rate', 'total', 'payment_status'].includes(col.field)
        );
    }
    if (unassigned) {
        columns = columns.filter((col) => col.field !== 'payment_status');
    }
    return columns;
};

/** Define additional charge Datagrid Fields. */
export const additionalChargeCols = (chargeList, handleDeleteClick, editable) => {
    return [
        {
            field: 'charge_type',
            headerName: 'Charge Type',
            type: 'singleSelect',
            align: 'center',
            headerAlign: 'center',
            flex: 1,
            editable: editable,
            renderCell: (params) => {
                const charge = chargeList.find((item) => item.id === params.value);
                return charge ? charge.name : '';
            },
            renderEditCell: (params) => {
                return (
                    <CustomAutocompleteCell
                        params={params}
                        options={chargeList}
                        value={params.value}
                        shouldFocus={true}
                    />
                );
            },
        },
        {
            field: 'addcharge_rate',
            headerName: 'Rate',
            type: 'number',
            align: 'center',
            headerAlign: 'center',
            flex: 0.5,
            editable: editable,
        },
        {
            field: 'qty',
            headerName: 'QTY',
            type: 'number',
            align: 'center',
            headerAlign: 'center',
            flex: 0.5,
            editable: editable,
        },
        {
            field: 'total',
            headerName: 'Total',
            type: 'number',
            align: 'center',
            headerAlign: 'center',
            flex: 0.5,
        },
        {
            field: 'addcharge_note',
            headerName: 'Note',
            align: 'center',
            headerAlign: 'center',
            flex: 2,
            editable: editable,
        },
        {
            field: 'actions',
            type: 'actions',
            headerName: 'Actions',
            width: 100,
            cellClassName: 'actions',
            getActions: ({ id }) => {
                return [
                    <GridActionsCellItem
                        icon={<DeleteIcon />}
                        label="Delete"
                        onClick={handleDeleteClick(id)}
                        color="inherit"
                        disabled={!editable}
                    />,
                ];
            },
        },
    ];
};
