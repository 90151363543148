import * as React from 'react';
import { useState, useEffect } from 'react';

/** Material UI Packages. */
import { Box, Tabs, Tab, Typography, useTheme, Paper } from '@mui/material';
import { DataGridPremium } from '@mui/x-data-grid-premium';
import PropTypes from 'prop-types';

/** Auth0 authenticator Packages. */
import { useAuth0 } from '@auth0/auth0-react';

/** HTTP Client Library. */
import axios from 'axios';

/** Internal Components. */
import { CustomExportToolbar } from '../../CustomExport';
import { CustomSkeletonVer2 } from '../../CustomSkeleton';
import { customized_style } from '../../../theme/Styles';
import ManifestCount from './ManifestCount';
import ManifestAssigned from './ManifestAssigned';
import { movements_col } from './TableDefinition';

/** Create tab panel component. */
function TabPanel(props) {
    const { children, value, index, ...other } = props;
    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`full-width-tabpanel-${index}`}
            aria-labelledby={`full-width-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ p: 3 }}>
                    <Typography component="div">{children}</Typography>
                </Box>
            )}
        </div>
    );
}

/** To run typechecking on the props for a component. */
TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
};

export default function Manifest(props) {
    /** Get props from App.js.  */
    const { userId } = props;

    /** Redirect user if the token is invalid */
    const { getAccessTokenSilently } = useAuth0();

    const manifestTheme = useTheme();

    /** Tab index value. */
    const [tabValue, setTabValue] = useState(0);

    /** State to display a loading indicator when initially fetching the page. */
    const [loaded, setLoaded] = useState(false);
    /** State to display loading for all API calls occurring after page rendering. */
    const [detailLoaded, setDetailLoaded] = useState(false);
    /** State for datagrid row(in progress / complete). */
    const [inprogressRow, setInprogressRow] = useState([]);
    const [completeRow, setCompleteRow] = useState([]);

    /** State for additional charge rate data. */
    const [addCharges, setAddCharges] = useState([]);
    /** State for the number of movements (inprogess / complete) */
    const [count, setCount] = useState();
    /** State for unassigned movement data. */
    const [unassigned, setUnassigned] = useState([]);

    /** True : In progress | False : Complete  */
    const [active, setActive] = useState(true);

    /** Set document title. */
    useEffect(() => {
        document.title = 'Manifest | Dispatch System ';
    }, []);

    /** Handler to change tabValue . */
    const tabHandleChange = (event, newValue) => {
        setTabValue(newValue);
    };

    /** Async API call to obtain dispatch - movement data. */
    useEffect(() => {
        (async () => {
            try {
                const token = await getAccessTokenSilently({
                    authorizationParams: {
                        audience:
                            'https://insightshippinginc.us.auth0.com/api/v2/' /** Include the audience of authorization parameters to authenticate API call. */,
                    },
                });
                const response = await axios.get('https://icxhzxkwxa.execute-api.us-west-1.amazonaws.com/api', {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                    params: {
                        type: 'manifest',
                        userId: userId,
                    },
                });
                const resp = response.data['body-json'];
                setInprogressRow(resp.body.allMovementRow);
                setCount(resp.body.count);
                setUnassigned(resp.body.unassigned);
                setAddCharges(resp.body.addCharges);
                setLoaded(true);
                setDetailLoaded(true);
                sessionStorage.removeItem('searchValue');
            } catch (e) {
                throw e;
            }
        })();
    }, [getAccessTokenSilently]);

    const movementCol = movements_col(true, true);

    return (
        <>
            {loaded ? (
                <Paper sx={customized_style.itemPaper_main}>
                    <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                        <Tabs
                            value={tabValue}
                            onChange={tabHandleChange}
                            indicatorColor="primary"
                            textColor="inherit"
                            variant="fullWidth"
                        >
                            <Tab label="ASSIGNED MOVEMENT" />
                            <Tab label="UNASSIGNED MOVEMENT" />
                        </Tabs>
                    </Box>
                    <TabPanel value={tabValue} index={0} dir={manifestTheme.direction}>
                        <Box sx={{ height: 635 }}>
                            <ManifestCount
                                userId={userId}
                                count={count}
                                setCount={setCount}
                                inprogressRow={inprogressRow}
                                setInprogressRow={setInprogressRow}
                                completeRow={completeRow}
                                setCompleteRow={setCompleteRow}
                                active={active}
                                setActive={setActive}
                                setDetailLoaded={setDetailLoaded}
                            />
                            <ManifestAssigned
                                userId={userId}
                                addCharges={addCharges}
                                inprogressRow={inprogressRow}
                                setInprogressRow={setInprogressRow}
                                completeRow={completeRow}
                                setCompleteRow={setCompleteRow}
                                active={active}
                                count={count}
                                detailLoaded={detailLoaded}
                                setDetailLoaded={setDetailLoaded}
                            />
                        </Box>
                    </TabPanel>
                    <TabPanel value={tabValue} index={1} dir={manifestTheme.direction}>
                        <Box sx={{ height: 635 }}>
                            <DataGridPremium
                                rows={unassigned}
                                columns={movementCol}
                                density="compact"
                                unstable_cellSelection
                                disableRowSelectionOnClick
                                slots={{
                                    toolbar: CustomExportToolbar,
                                }}
                                slotProps={{
                                    toolbar: {
                                        showQuickFilter: true,
                                    },
                                }}
                                sx={{ border: 'none', height: '82.5vh' }}
                            />
                        </Box>
                    </TabPanel>
                </Paper>
            ) : (
                <Paper sx={{ ...customized_style.itemPaper_main, p: 2 }}>
                    <CustomSkeletonVer2 />
                </Paper>
            )}
        </>
    );
}
